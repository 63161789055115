import { Select } from "antd";
import { EmailTemplate } from "../../../utils/types";

interface Props {
  templates: EmailTemplate[];
  selectedTemplates: string[];
  setSelectedTemplates: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function SelectTemplates(props: Props) {
  const handleChange = (value: string[]) => {
    props.setSelectedTemplates(value);
  };

  return (
    <div>
      <p>
        The AI's response will be derived from the email templates that you have
        carefully selected. The resulting email template, generated by the AI,
        will be meticulously customized to perfectly align with your unique
        personal style. Please note that you have the option to select a maximum
        of five templates.
      </p>
      <Select
        mode="multiple"
        placeholder="Select email templates"
        className="full-width"
        onChange={handleChange}
        value={props?.selectedTemplates}
        options={props?.templates?.map((template: EmailTemplate) => ({
          value: template._id?.toString(),
          label: template?.name,
        }))}
      />
    </div>
  );
}
