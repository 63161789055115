import { Button, Empty, Popconfirm, Row, message } from "antd";
import { AdminPromptCategoriesI, AdminSettings } from "../../../utils/types";
import ListCard from "../../ListCard";
import AdminService from "../../../services/AdminService";
import Loading from "../../Loading";
import ResponseError from "../../../utils/api/ResponseError";
import { store } from "../../../store/store";
import { GlobalSliceReducers } from "../../../store/slices/GlobalSlice";
import AdminPromptCategoryForm from "../AdminPromptCategoryForm";

interface Props {
  promptCategories: AdminPromptCategoriesI[];
  loadPromptCategories: Function;
  loading: boolean;
  prompts: AdminSettings[];
}

export default function AdminPromptCategories(props: Props) {
  const showAddCategoryModal = async () => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: (
          <AdminPromptCategoryForm
            prompts={props.prompts}
            onSuccessCallback={() => {
              store.dispatch(GlobalSliceReducers.closeModal());
              props?.loadPromptCategories();
            }}
          />
        ),
      })
    );
  };

  const showEditModal = async (promptCategory: AdminPromptCategoriesI) => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: (
          <AdminPromptCategoryForm
            edit
            promptCategory={promptCategory}
            onSuccessCallback={() => {
              store.dispatch(GlobalSliceReducers.closeModal());
              props?.loadPromptCategories();
            }}
            prompts={props.prompts}
          />
        ),
      })
    );
  };

  const deletePromptCategory = async (id: string) => {
    try {
      await AdminService.deletePromptCategory({
        query: { id },
      });
      props?.loadPromptCategories();
      message.success("Prompt Category deleted successfully");
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    }
  };

  return (
    <div>
      <Button onClick={showAddCategoryModal}>Add Category</Button>
      <hr />
      {props.promptCategories.length > 0 && (
        <Row gutter={[16, 16]}>
          {props.promptCategories.map(
            (elem: AdminPromptCategoriesI, index: number) => {
              const promptName = props?.prompts?.find((item) => item._id === elem.prompt)?.name
              return (
                <ListCard
                  key={index}
                  title={elem.category}
                  description={`${promptName}`}
                  actions={[
                    <span
                      onClick={() => {
                        showEditModal(elem);
                      }}
                    >
                      Edit
                    </span>,
                    <Popconfirm
                      title="Delete Prompt Category"
                      onConfirm={() => deletePromptCategory(elem._id)}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <span>Delete</span>
                    </Popconfirm>
                  ]}
                />
              );
            }
          )}
        </Row>
      )}
      {props?.promptCategories.length === 0 && !props?.loading && (
        <Empty description="No prompt category found." />
      )}
      {props?.loading && <Loading />}
    </div>
  );
}
//
