import { Endpoint } from "../utils/api/Endpoints";
import {
  ApiFetchRequestOptions,
  BaseApiResponse,
  GoogleAuthSession,
} from "../utils/types";
import ApiProvider from "./AbstractApiProvider";

class GoogleService extends ApiProvider {
  public async setupOAuth(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<string>> {
    return this.callApi(Endpoint.google.setupOAuth, options);
  }
  public async oAuthCallback(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<GoogleAuthSession>> {
    return this.callApi(Endpoint.google.oAuthCallback, options);
  }
  public async loadAccounts(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<GoogleAuthSession[]>> {
    return this.callApi(Endpoint.google.accounts, options);
  }
  public async removeAccount(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<boolean>> {
    return this.callApi(Endpoint.google.removeAccount, options);
  }
}

export default new GoogleService();
