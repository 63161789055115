import { useState } from "react";
import { Button, Form, Input, Select, Typography, message } from "antd";
import AppTexts from '../../../utils/texts/AppTexts.json'
import { AdminPromptCategoriesI, AdminSettings } from "../../../utils/types";
import ResponseError from "../../../utils/api/ResponseError";
import AdminService from "../../../services/AdminService";

interface Props {
  edit?: boolean;
  promptCategory?: AdminPromptCategoriesI;
  prompts: AdminSettings[];
  onSuccessCallback?: Function;
}

export default function AdminPromptCategoryForm(props: Props) {
  const [loading, setLoading] = useState(false);

  const onSubmitSucceed = async (data: Omit<AdminPromptCategoriesI, "_id">) => {
    try {
      const query = {id: props?.promptCategory?._id}
      const response = props?.edit 
        ? await AdminService.editPromptCategory({data, query})
        : await AdminService.addPromptCategory({data})

      message.success(`Prompt Category ${props?.edit ? "edited" : "added"} successfully`)

      if(props?.onSuccessCallback) props.onSuccessCallback(response)
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const onSubmitFailed = () => {};

  return (
    <div>
      <Typography.Title level={2}>
        {`${props?.edit ? "Edit this" : "Add a"} prompt category.`}
      </Typography.Title>
      <Form
        key="prompt_category"
        onFinish={onSubmitSucceed}
        onFinishFailed={onSubmitFailed}
      >
        <Form.Item
          name="category"
          rules={[{ required: true, message: AppTexts.global.field_required }]}
          initialValue={props?.promptCategory?.category}
        >
          <Input placeholder="Category name" />
        </Form.Item>

        <Form.Item
          name="prompt"
          rules={[{ required: true, message: AppTexts.global.field_required }]}
          initialValue={props.promptCategory?.prompt}
        >
          <Select placeholder="Select prompt for this category">
            {props?.prompts?.map((elem: AdminSettings, index: number) => {
              return (
                <Select.Option key={`${index}`} value={elem._id}>
                  {elem.name}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>

        <Button
          disabled={loading}
          loading={loading}
          type="primary"
          htmlType="submit"
        >
          {props?.edit ? "Edit" : "Add"}
        </Button>
      </Form>
    </div>
  );
}
