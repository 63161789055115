import React from "react";
import { Layout, Menu } from "antd";
import {
  HomeOutlined,
  FileMarkdownOutlined,
  TabletOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Sider } = Layout;

interface IUserLayoutProps {
  children: React.ReactElement;
}

export default function UserLayout(props: IUserLayoutProps) {
  return (
    <Layout className="user-layout-wrapper">
      <Sider width={200} className="sider">
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          className="aside-menu"
        >
          <Menu.Item key="/" icon={<HomeOutlined />}>
            <Link to="/">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="/organization/templates" icon={<TabletOutlined />}>
            <Link to="/organization/templates">Templates</Link>
          </Menu.Item>
          <Menu.Item
            key="//organization/manage"
            icon={<FileMarkdownOutlined />}
          >
            <Link to="/organization/manage">Organizations</Link>
          </Menu.Item>
          <Menu.Item
            key="/organization/contacts"
            icon={<FileMarkdownOutlined />}
          >
            <Link to="/organization/contacts">Contacts</Link>
          </Menu.Item>
          <Menu.Item key="/organization/email" icon={<FileMarkdownOutlined />}>
            <Link to="/organization/email">Email</Link>
          </Menu.Item>
          <Menu.Item key="/organization/media-library" icon={<FileMarkdownOutlined />}>
            <Link to="/organization/media-library">Media Library</Link>
          </Menu.Item>
          {/* <Menu.Item
            key="/organization/contacts"
            icon={<FileMarkdownOutlined />}
          >
            <Link to="/organization/contacts">Contacts</Link>
          </Menu.Item>
          <Menu.Item key="/gmail/accounts" icon={<FileMarkdownOutlined />}>
            <Link to="/gmail/accounts">Gmail</Link>
          </Menu.Item>
          <Menu.Item
            key="/organization/email-history"
            icon={<FileMarkdownOutlined />}
          >
            <Link to="/organization/email-history">History</Link>
          </Menu.Item> */}
        </Menu>
      </Sider>
      <Layout>
        <Layout.Content className="layout-content">
          {props.children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
