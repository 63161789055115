import { Button, Card, Space, Typography } from "antd";
import Content from "../../Content";
import AppTexts from "../../../utils/texts/AppTexts.json";
import { store } from "../../../store/store";
import { GlobalSliceReducers } from "../../../store/slices/GlobalSlice";
import EnterEmail from "./EnterEmail";
import SendEmailForm from "../../Forms/SendEmailForm";
import { CustomContact } from "../../../utils/types";

export default function QuickEmail() {
  const quickSend = () => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: (
          <EnterEmail
            onSubmitCallback={(data: { email: string; name?: string }) => {
              store.dispatch(
                GlobalSliceReducers.showModal({
                  component: (
                    <SendEmailForm
                      contact={data as CustomContact}
                      onClose={() => {
                        store.dispatch(GlobalSliceReducers.closeModal());
                      }}
                    />
                  ),
                })
              );
            }}
          />
        ),
      })
    );
  };
  return (
    <div className="layout">
      <Content center>
        <Card className="quick-email-card">
          <Space direction="vertical">
            <Typography.Title level={4}>
              {AppTexts.organization_email_page.quick_send.title}
            </Typography.Title>

            <Typography.Paragraph>
              {AppTexts.organization_email_page.quick_send.description}
            </Typography.Paragraph>

            <Button block onClick={quickSend}>
              {AppTexts.organization_email_page.quick_send.button_title}
            </Button>
          </Space>
        </Card>
      </Content>
    </div>
  );
}
