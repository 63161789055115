import { Row, Empty, message, Popconfirm } from "antd";
import { AdminSettings } from "../../../utils/types";

import ResponseError from "../../../utils/api/ResponseError";
import AdminService from "../../../services/AdminService";
import { useNavigate } from "react-router-dom";
import ListCard from "../../ListCard";
import Loading from "../../Loading";

interface Props {
  prompts: AdminSettings[];
  loading: boolean;
  loadGPTPrompts: Function;
  loadPromptCategories: Function;
}

export default function AdminListGPTPrompts(props: Props) {
  const navigate = useNavigate();
  const deletePrompt = async (prompt: AdminSettings) => {
    try {
      await AdminService.deleteGPTPrompt({
        query: { id: prompt?._id },
      });
      props?.loadGPTPrompts();
      props?.loadPromptCategories();
      message.success("Prompt deleted successfully");
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    }
  };

  const editPrompt = (prompt: AdminSettings) => {
    navigate(`/admin/update-gpt-prompt?id=${prompt?._id}`);
  };

  return (
    <div>
      {props?.prompts?.length > 0 && !props?.loading && (
        <Row gutter={[16, 16]}>
          {props?.prompts?.map((prompt: AdminSettings, index: number) => (
            <ListCard
              key={index}
              title={prompt?.name}
              description={prompt?.ai_prompt?.substring(0, 50) + "..."}
              actions={[
                <span
                  onClick={() => {
                    editPrompt(prompt);
                  }}
                >
                  Edit
                </span>,
                <Popconfirm
                  title="Delete the prompt?"
                  description="Are you sure to delete this prompt?"
                  onConfirm={() => deletePrompt(prompt)}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <span>Delete</span>
                </Popconfirm>,
              ]}
            />
          ))}
        </Row>
      )}

      {props?.prompts?.length === 0 && !props?.loading && (
        <Empty description="No prompts found." />
      )}

      {props?.loading && <Loading />}
    </div>
  );
}
