import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../store/store";
import constants from "../utils/constants";

interface Props {
  children: React.ReactElement;
}

const NonAuthRoute: React.FC<Props> = ({ children }) => {
  const auth = useSelector((state: RootState) => state?.auth);

  if (auth.value?.session?.access_token) {
    return (
      <Navigate
        to={
          auth?.value?.user?.role === constants.user.roles.ADMIN
            ? "/admin/gpt-prompts"
            : "/"
        }
        replace
      />
    );
  }

  return children;
};

export default NonAuthRoute;
