import { Row, message } from "antd";
import ListCard from "../ListCard";
import { SuggestedTemplate } from "../../utils/types";
import Loading from "../Loading";
import ResponseError from "../../utils/api/ResponseError";
import OrganizationService from "../../services/OrganizationService";
import { store } from "../../store/store";
import { GlobalSliceReducers } from "../../store/slices/GlobalSlice";
import ViewTemplate from "./ViewTemplate";

interface Props {
  suggestedTemplates: SuggestedTemplate[];
  loading: boolean;
  loadTemplates: Function;
}

export default function SuggestedTemplates(props: Props) {
  const handleOnClickSuggestedTemplate = async (
    template: SuggestedTemplate
  ) => {
    try {
      await OrganizationService.createEmailTemplate({
        data: {
          name: template?.name,
          content: template?.content,
        },
      });
      props?.loadTemplates();
      message.success("Successfully moved to your templates.");
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    }
  };

  const viewTemplate = (template: SuggestedTemplate) => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: <ViewTemplate template={template} />,
      })
    );
  };

  return (
    <div>
      {props?.loading && <Loading />}
      {!props?.loading && props?.suggestedTemplates?.length > 0 && (
        <Row gutter={[16, 16]}>
          {props?.suggestedTemplates?.map((template) => (
            <ListCard
              key={template?._id}
              title={template?.name}
              description={template?.content?.substring(0, 200) + "..."}
              actions={[
                <span onClick={() => handleOnClickSuggestedTemplate(template)}>
                  Use it
                </span>,
                <span onClick={() => viewTemplate(template)}>View</span>,
              ]}
            />
          ))}
        </Row>
      )}
    </div>
  );
}
