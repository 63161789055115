const API_URL = process.env.REACT_APP_API_URL;

export const Endpoint = {
  user: {
    auth: {
      login: { url: `${API_URL}/api/users/login`, method: "POST" },
      register: { url: `${API_URL}/api/users/register`, method: "POST" },
      logout: { url: `${API_URL}/api/users/logout`, method: "POST" },
      reGenerateAccessToken: {
        url: `${API_URL}/api/users/re-generate-access-token`,
        method: "POST",
      },
      verify_account: {
        url: `${API_URL}/api/users/verify-account`,
        method: "POST",
      },
      forgot_password: {
        url: `${API_URL}/api/users/forgot-password`,
        method: "POST",
      },
      reset_password: {
        url: `${API_URL}/api/users/reset-password`,
        method: "POST",
      },
      two_factor_auth_submit: {
        url: `${API_URL}/api/users/two-factor-auth-submit`,
        method: "POST",
      },
      update_profile: {
        url: `${API_URL}/api/users/update-profile`,
        method: "POST",
      },
      update_password: {
        url: `${API_URL}/api/users/update-password`,
        method: "POST",
      },
      get_profile: {
        url: `${API_URL}/api/users/me`,
        method: "GET",
      },
      get_user_settings: {
        url: `${API_URL}/api/users/settings`,
        method: "GET",
      },
      update_user_settings: {
        url: `${API_URL}/api/users/settings/update`,
        method: "POST",
      },
    },
    organizations: {
      list: { url: `${API_URL}/api/users/organizations/list`, method: "GET" },
      create: {
        url: `${API_URL}/api/users/organizations/create`,
        method: "POST",
      },
      delete: {
        url: `${API_URL}/api/users/organizations/delete`,
        method: "POST",
      },
      update: {
        url: `${API_URL}/api/users/organizations/update`,
        method: "POST",
      },
      updateLiveMode: {
        url: `${API_URL}/api/users/organizations/update-live-mode`,
        method: "POST",
      },
      dashboard: {
        statistics: {
          url: `${API_URL}/api/users/organizations/dashboard-statistics`,
          method: "GET",
        },
      },
      addCustomContact: {
        url: `${API_URL}/api/users/organizations/add-custom-contact`,
        method: "POST",
      },
       getContactProfile:{
        url: `${API_URL}/api/users/organizations/getContactProfile`,
        method: "GET",
       },
      
      loadCustomContacts: {
        url: `${API_URL}/api/users/organizations/load-custom-contacts`,
        method: "GET",
      },
      emailTemplates: {
        create: {
          url: `${API_URL}/api/users/organizations/templates/create`,
          method: "POST",
        },
        update: {
          url: `${API_URL}/api/users/organizations/templates/update`,
          method: "POST",
        },
        delete: {
          url: `${API_URL}/api/users/organizations/templates/delete`,
          method: "POST",
        },
        list: {
          url: `${API_URL}/api/users/organizations/templates/list`,
          method: "GET",
        },
        generate: {
          url: `${API_URL}/api/users/organizations/templates/generate-template`,
          method: "POST",
        },
        generateSubject: {
          url: `${API_URL}/api/users/organizations/templates/generate-subject`,
          method: "POST",
        },
        send: {
          url: `${API_URL}/api/users/organizations/templates/send`,
          method: "POST",
        },
        generateSuggestion: {
          url: `${API_URL}/api/users/organizations/templates/generate-template-suggestion`,
          method: "POST",
        },
        listSuggestedTemplates: {
          url: `${API_URL}/api/users/organizations/templates/list-suggested-templates`,
          method: "GET",
        },
      },
      emailsEventsHistory: {
        url: `${API_URL}/api/users/organizations/emails-events-history`,
        method: "GET",
      },
      listTemplateSuggestions: {
        url: `${API_URL}/api/users/organizations/list-template-suggestions`,
        method: "GET",
      },
      mediaLibrary: {
        list: {
          url: `${API_URL}/api/users/organizations/media-library`,
          method: "GET",
        },
        single: {
          url: `${API_URL}/api/users/organizations/media-library`,
          method: "GET",
        },
        upload: {
          url: `${API_URL}/api/users/organizations/media-library/upload`,
          method: "POST",
        },
        delete: {
          url: `${API_URL}/api/users/organizations/media-library/delete`,
          method: "POST",
        },
      },
      subjects: {
        list: {
          url: `${API_URL}/api/users/organizations/settings/subjects`,
          method: "GET",
        },
        single: {
          url: `${API_URL}/api/users/organizations/settings/single-subject`,
          method: "GET",
        },
        add: {
          url: `${API_URL}/api/users/organizations/settings/add-subject`,
          method: "POST",
        },
        delete: {
          url: `${API_URL}/api/users/organizations/settings/delete-subject`,
          method: "POST",
        },
        update: {
          url: `${API_URL}/api/users/organizations/settings/update-subject`,
          method: "POST",
        },
      },
    },
  },
  hubspot: {
    setupOAuth: {
      url: `${API_URL}/api/hubspot/oauth`,
      method: "POST",
    },
    oAuthCallback: {
      url: `${API_URL}/api/hubspot/oauth-callback`,
      method: "POST",
    },
    contactProfile:{
      url: `${API_URL}/api/hubspot/contact-profile`,
      method: "GET",
    },

    checkOAuthStatus: {
      url: `${API_URL}/api/hubspot/check-oauth-status`,
      method: "POST",
    },
    contacts: {
      url: `${API_URL}/api/hubspot/contacts`,
      method: "GET",
    },
    searchContact: {
      url: `${API_URL}/api/hubspot/search-contact`,
      method: "GET",
    },
  },
  google: {
    setupOAuth: {
      url: `${API_URL}/api/google/oauth-url`,
      method: "POST",
    },
    oAuthCallback: {
      url: `${API_URL}/api/google/oauth-callback`,
      method: "POST",
    },
    removeAccount: {
      url: `${API_URL}/api/google/remove-account`,
      method: "POST",
    },
    accounts: {
      url: `${API_URL}/api/google/accounts`,
      method: "GET",
    },
  },
  admin: {
    settings: {
      addGPTPrompt: {
        url: `${API_URL}/api/admin/settings/add-gpt-prompt`,
        method: "POST",
      },
      listGPTPrompt: {
        url: `${API_URL}/api/admin/settings/list-gpt-prompt`,
        method: "GET",
      },
      deleteGPTPrompt: {
        url: `${API_URL}/api/admin/settings/delete-gpt-prompt`,
        method: "POST",
      },
      singleGPTPrompt: {
        url: `${API_URL}/api/admin/settings/single-gpt-prompt`,
        method: "GET",
      },
      updateGPTPrompt: {
        url: `${API_URL}/api/admin/settings/update-gpt-prompt`,
        method: "POST",
      },
      listPromptCategories: {
        url: `${API_URL}/api/admin/settings/list-prompt-categories`,
        method: "GET",
      },
      singlePromptCategory: {
        url: `${API_URL}/api/admin/settings/single-prompt-category`,
        method: "GET",
      },
      editPromptCategory: {
        url: `${API_URL}/api/admin/settings/edit-prompt-category`,
        method: "POST",
      },
      addPromptCategory: {
        url: `${API_URL}/api/admin/settings/add-prompt-category`,
        method: "POST",
      },
      deletePromptCategory: {
        url: `${API_URL}/api/admin/settings/delete-prompt-category`,
        method: "POST",
      },
      quickGPT: {
        url: `${API_URL}/api/admin/settings/quick-gpt`,
        method: "POST",
      },
      listSubjects: {
        url: `${API_URL}/api/admin/settings/subjects`,
        method: "GET",
      },
      deleteSubject: {
        url: `${API_URL}/api/admin/settings/delete-subject`,
        method: "POST",
      },
      updateSubject: {
        url: `${API_URL}/api/admin/settings/update-subject`,
        method: "POST",
      },
      addSubject: {
        url: `${API_URL}/api/admin/settings/add-subject`,
        method: "POST",
      },
      singleSubject: {
        url: `${API_URL}/api/admin/settings/single-subject`,
        method: "GET",
      },
    },
    templates: {
      add: {
        url: `${API_URL}/api/admin/templates/add`,
        method: "POST",
      },
      list: {
        url: `${API_URL}/api/admin/templates/list`,
        method: "GET",
      },
      delete: {
        url: `${API_URL}/api/admin/templates/delete`,
        method: "POST",
      },
      update: {
        url: `${API_URL}/api/admin/templates/update`,
        method: "POST",
      },
    },
    suggestions: {
      add: {
        url: `${API_URL}/api/admin/suggestions/add`,
        method: "POST",
      },
      list: {
        url: `${API_URL}/api/admin/suggestions/list`,
        method: "GET",
      },
      delete: {
        url: `${API_URL}/api/admin/suggestions/delete`,
        method: "POST",
      },
    },
  },
};
