import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import {
  AdminTemplateSuggestion,
  GlobalSlice,
  Organization,
} from "../../utils/types";

interface ShowModalI {
  component: JSX.Element;
}

const persistConfig = {
  key: "global-redux-slice",
  storage,
};

const initialState: { value: GlobalSlice } = {
  value: {
    organizations: [],
    template_suggestions: [],
    modal: {
      visible: false,
      component: null,
    },
  },
};

export const globalSlice = createSlice({
  name: "global-slice",
  initialState,
  reducers: {
    setOrganizations: (state, action: PayloadAction<Organization[]>) => {
      state.value.organizations = [...action.payload];
    },
    setTemplateSuggestions: (
      state,
      action: PayloadAction<AdminTemplateSuggestion[]>
    ) => {
      state.value.template_suggestions = [...action.payload];
    },
    closeModal: (state) => {
      state.value.modal.visible = false;
      state.value.modal.component = null;
    },
    showModal: (state, action: PayloadAction<ShowModalI>) => {
      state.value.modal.visible = true;
      state.value.modal.component = action.payload.component;
    },
  },
});

export const GlobalSliceReducers = globalSlice.actions;

export default persistReducer(persistConfig, globalSlice.reducer);
