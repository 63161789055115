import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import AdminLayout from "../../../components/Layouts/AdminLayout";
import Header from "../../../components/Header";
import { Breadcrumb, Space } from "antd";
import AdminSubjectsForm from "../../../components/Admin/AdminSubjectsForm";
import { Link } from "react-router-dom";

export default function AdminUpdateSubjectsPage() {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state?.auth?.value);
  return (
    <div className="layout">
      <Header dispatch={dispatch} auth={auth} />
      <AdminLayout>
        <div>
          <Space direction="vertical" size={20}>
            <Breadcrumb
              items={[
                {
                  title: <Link to="/admin/subjects">All Subjects</Link>,
                },
                {
                  title: "Update subject",
                },
              ]}
            />
            <AdminSubjectsForm />
          </Space>
        </div>
      </AdminLayout>
    </div>
  );
}
