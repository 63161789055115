import { useSelector } from "react-redux";
import Header from "../../components/Header";
import { WithGlobalData } from "../../utils/hooks/withGlobalData";
import { WithGlobalDataProps } from "../../utils/types";
import { RootState, store } from "../../store/store";
import { useDispatch } from "react-redux";
import UserLayout from "../../components/Layouts/UserLayout";
import { useEffect, useState } from "react";
import OrganizationService from "../../services/OrganizationService";
import { Button, Row, Typography, message } from "antd";

import AppTexts from "../../utils/texts/AppTexts.json";

import pdfImage from "../../assets/images/pdf.png";
import Loading from "../../components/Loading";
import { GlobalSliceReducers } from "../../store/slices/GlobalSlice";
import UploadMediasForm from "../../components/Forms/UploadMediasForm";
import ResponseError from "../../utils/api/ResponseError";

interface IMediaLibraryProps extends WithGlobalDataProps {}

const API_URL = process.env.REACT_APP_API_URL;

const MediaLibraryPage = (props: IMediaLibraryProps) => {
  const [mediaKeys, setMediaKeys] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const auth = useSelector((state: RootState) => state.auth.value);
  const organization = useSelector(
    (state: RootState) => state.organization.value
  );
  const dispatch = useDispatch();

  const loadMedias = async () => {
    try {
      setLoading(true);
      const response = await OrganizationService.listMediaKeys();
      const arr: string[] = [];
      for (let i = 0; i < response?.message?.Contents?.length; i++) {
        arr.push(response?.message?.Contents?.[i].Key);
      }
      setMediaKeys(arr);
    } catch (err) {
      message.error(ResponseError.showMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const deleteMedia = async (key: string) => {
    try {
      const query = { key };
      await OrganizationService.deleteFile({ query });
      store.dispatch(GlobalSliceReducers.closeModal());
      loadMedias();
    } catch (error: unknown) {
      message.error(ResponseError.showMessage(error));
    } finally {
    }
  };

  const showUploadModal = async () => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: (
          <UploadMediasForm
            onSuccessCallback={async () => {
              store.dispatch(GlobalSliceReducers.closeModal());
              loadMedias();
            }}
          />
        ),
      })
    );
  };

  const showDeleteModal = async (key: string) => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: (
          <>
            <Typography.Title level={3}>
              {AppTexts.media_library.confirm_delete}
            </Typography.Title>
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  store.dispatch(GlobalSliceReducers.closeModal());
                }}
              >
                {AppTexts.global.no}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  deleteMedia(key);
                }}
              >
                {AppTexts.global.yes}
              </Button>
            </div>
          </>
        ),
      })
    );
  };

  useEffect(() => {
    loadMedias();
  }, []);

  return (
    <div className="layout">
      <Header
        dispatch={dispatch}
        organization={organization}
        organizations={props.organizations}
        auth={auth}
      />
      <UserLayout>
        <>
          <Button onClick={showUploadModal}>
            {AppTexts.media_library.upload_files}
          </Button>
          <hr />
          <Row gutter={[16, 16]}>
            {loading && <Loading />}
            {!loading &&
              mediaKeys.map((key, index: number) => {
                return (
                  <div
                    style={{ position: "relative", padding: 16 }}
                    key={`${index}`}
                  >
                    <a
                      rel="noreferrer"
                      style={{ padding: 8 }}
                      href={`${API_URL}/api/users/organizations/media-library/get?fileName=${key}`}
                      target="_blank"
                    >
                      <img
                        alt={key}
                        width={200}
                        src={
                          key.endsWith(".pdf")
                            ? pdfImage
                            : `${API_URL}/api/users/organizations/media-library/get?fileName=${key}`
                        }
                      />
                    </a>
                    <div style={{ textAlign: "center" }}>
                      <Button type="text" onClick={() => showDeleteModal(key)}>
                        {AppTexts.global.delete}
                      </Button>
                    </div>
                  </div>
                );
              })}
          </Row>
        </>
      </UserLayout>
    </div>
  );
};

export default WithGlobalData(MediaLibraryPage);
