import { useState } from "react";
import { Button, Form, Typography, Upload, message } from "antd";
import { UploadOutlined } from '@ant-design/icons';

import ResponseError from "../../../utils/api/ResponseError";
import OrganizationService from "../../../services/OrganizationService";
import AppTexts from "../../../utils/texts/AppTexts.json"

interface IUploadMediasProps {
  onSuccessCallback?: Function;
}

export default function UploadMediasForm(props: IUploadMediasProps) {
  const [loading, setLoading] = useState(false)
  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const onSubmitSucceed = async (data: { media_library: [{ originFileObj: File }] }) => {
    const formData = new FormData()
    if (data?.media_library) {
      data.media_library.forEach(media => {
        formData.append(`media_library`, media.originFileObj)
      })
    }

    try {
      setLoading(true)
      await OrganizationService.uploadFiles({ data: formData })
      message.success(AppTexts.media_library.upload_successfully)
      if (props?.onSuccessCallback) {
        props.onSuccessCallback()
      }
    } catch (error: unknown) {
      message.error(ResponseError.showMessage(error))
    } finally {
      setLoading(false)
    }
  }

  const onSubmitFailed = () => { };

  return (
    <div>
      <Typography.Title level={2}>
        {AppTexts.media_library.upload_title}
      </Typography.Title>
      <Form
        key="media-library"
        onFinish={onSubmitSucceed}
        onFinishFailed={onSubmitFailed}

      >
        <Form.Item
          name="media_library"
          rules={[{ required: true, message: AppTexts.media_library.files_required }]}
          valuePropName="fileList"
          getValueFromEvent={getFile}
        >
          <Upload accept="image/png, image/jpg, image/jpeg, application/pdf" multiple maxCount={20} beforeUpload={() => false} >
            <Button icon={<UploadOutlined />}>{AppTexts.media_library.click_to_upload}</Button>
          </Upload>
        </Form.Item>

        <Button
          loading={loading}
          disabled={loading}
          type="primary"
          htmlType="submit"
        >
          {AppTexts.global.confirm}
        </Button>
      </Form >
    </div>
  )
}