import { Dispatch, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Select,
  Typography,
  message,
} from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

import AppText from "../../../utils/texts/AppTexts.json";
import { RootState } from "../../../store/store";
import {
  GoogleAuthSession,
  Organization,
  UserSettings,
} from "../../../utils/types";
import UserService from "../../../services/UserService";
import ResponseError from "../../../utils/api/ResponseError";
import { AnyAction } from "redux";

interface props {
  accounts: GoogleAuthSession[];
  settings: UserSettings | undefined;
  organization?: Organization;
  dispatch: Dispatch<AnyAction>;
}

export default function SettingsForm(props: props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(
    !!props.settings?.two_factor_auth_enabled
  );
  const organizations = useSelector(
    (state: RootState) => state.global.value.organizations
  );

  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  const onSubmitSucceed = async (data: UserSettings) => {
    if (!data.two_factor_auth_enabled) {
      data.two_factor_auth_enabled = false;
    }
    setLoading(true);
    try {
      const response = await UserService.UpdateUserSettings({ data });
      if (response.message) {
        message.success("Settings Updated Successfully");
      }
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="profile-info-form">
      <Typography.Title level={3}>
        {AppText.auth.profile_page.steps.settings.title_profile}
      </Typography.Title>
      <Typography.Paragraph>
        {AppText.auth.profile_page.steps.settings.content_profile}
      </Typography.Paragraph>
      <Form
        name="update-user-settings"
        layout="horizontal"
        onFinish={onSubmitSucceed}
      >
        <Form.Item
          name="default_email"
          label="Default Email"
          rules={[{ required: true }]}
          initialValue={
            props.settings?.default_email || props.accounts.length === 1
              ? props.accounts[0].email
              : undefined
          }
        >
          <Select placeholder="Select a default email">
            {props.accounts.map((email: GoogleAuthSession, index: number) => {
              return (
                <Select.Option key={`${index}`} value={email.email}>
                  {email.email}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="default_organization"
          label="Default Organization"
          rules={[{ required: true }]}
          initialValue={props?.organization?._id}
        >
          <Select placeholder="Please select a default organization">
            {organizations.map((organization: Organization, index: number) => {
              return (
                <Select.Option key={`${index}`} value={organization._id}>
                  {organization.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Two Factor Authentication"
          name="two_factor_auth_enabled"
          valuePropName="checked"
          initialValue={checked}
        >
          <Checkbox checked={checked} onChange={onChange} />
        </Form.Item>

        <div>
          <Button loading={loading} type="primary" htmlType="submit">
            {AppText.global.save}
          </Button>
        </div>
      </Form>
    </Card>
  );
}
