import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";
import Header from "../../components/Header";
import UserLayout from "../../components/Layouts/UserLayout";
import { useSelector } from "react-redux";
import { RootState, store } from "../../store/store";
import { useDispatch } from "react-redux";
import AppTexts from "../../utils/texts/AppTexts.json";
import Content from "../../components/Content";
import EmailTemplateForm from "../../components/Forms/EmailTemplateForm";
import OrganizationService from "../../services/OrganizationService";
import {
  AdminTemplateSuggestion,
  EmailTemplate,
  SuggestedTemplate,
  TabParams,
  WithGlobalDataProps,
} from "../../utils/types";
import ResponseError from "../../utils/api/ResponseError";
import { WithGlobalData } from "../../utils/hooks/withGlobalData";
import { GlobalSliceReducers } from "../../store/slices/GlobalSlice";
import Loading from "../../components/Loading";
import SuggestedTemplates from "../../components/Templates/SuggestedTemplates";
import ListTemplates from "../../components/Templates/ListTemplates";

interface ITemplatesPage extends WithGlobalDataProps {}

const TemplatesPage = (props: ITemplatesPage) => {
  const [form] = Form.useForm();
  const auth = useSelector((state: RootState) => state.auth.value);
  const organization = useSelector(
    (state: RootState) => state.organization.value
  );
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [loading, setLoading] = useState(true);
  const [suggestedTemplatesLoading, setSuggestedTemplatesLoading] =
    useState(true);
  const [suggestedTemplates, setSuggestedTemplates] = useState<
    SuggestedTemplate[]
  >([]);

  const loadTemplates = async () => {
    try {
      const response = await OrganizationService.listEmailTemplates({});
      setTemplates(response?.message);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const listSuggestedTemplates = async () => {
    try {
      const response = await OrganizationService.listSuggestedTemplates();
      setSuggestedTemplates(response?.message);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setSuggestedTemplatesLoading(false);
    }
  };

  const deleteTemplate = async (template: EmailTemplate) => {
    try {
      const query = {
        _id: template._id,
      };
      await OrganizationService.deleteEmailTemplate({
        query,
      });
      loadTemplates();
    } catch (err: unknown) {
      ResponseError.showMessage(err);
    }
  };

  const showEditModal = (template: EmailTemplate) => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: (
          <EmailTemplateForm
            edit
            template={template}
            onSuccessCallback={() => {
              store.dispatch(GlobalSliceReducers.closeModal());
              loadTemplates();
            }}
          />
        ),
      })
    );
  };

  const generateTemplateSuggestion = async (
    suggestion: AdminTemplateSuggestion
  ) => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: <Loading />,
      })
    );

    try {
      const data = { id: suggestion?._id };
      const response = await OrganizationService.generateTemplateSuggestion({
        data,
      });
      store.dispatch(GlobalSliceReducers.closeModal());
      form.setFieldValue("content", response?.message || "");
      message.success(
        AppTexts.organization_templates.templates_suggestions
          .suggestion_generated_success
      );
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    }
  };

  useEffect(() => {
    loadTemplates();
    listSuggestedTemplates();
  }, []);

  const components: TabParams[] = [
    {
      title: "Suggested templates",
      component: (
        <SuggestedTemplates
          loading={suggestedTemplatesLoading}
          suggestedTemplates={suggestedTemplates}
          loadTemplates={loadTemplates}
        />
      ),
    },
    {
      title: "Your templates",
      component: (
        <ListTemplates
          loading={loading}
          templates={templates}
          onEdit={(template: EmailTemplate) => showEditModal(template)}
          onDelete={(template: EmailTemplate) => deleteTemplate(template)}
        />
      ),
    },
    {
      title: "Add new",
      component: (
        <Content>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Card>
                <Typography.Title level={2}>
                  {AppTexts.organization_templates.title}
                </Typography.Title>
                <Space direction="vertical" size={30}>
                  <Typography.Text>
                    {AppTexts.organization_templates.description}
                  </Typography.Text>

                  <EmailTemplateForm
                    form={form}
                    onSuccessCallback={() => {
                      loadTemplates();
                    }}
                  />
                </Space>
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <Typography.Title level={2}>
                  {AppTexts.organization_templates.templates_suggestions.title}
                </Typography.Title>
                <Space direction="vertical" size={30}>
                  <Typography.Text>
                    {
                      AppTexts.organization_templates.templates_suggestions
                        .description
                    }
                  </Typography.Text>
                  <Row gutter={24} wrap={true}>
                    {props?.template_suggestions?.length > 0 &&
                      props?.template_suggestions?.map((el) => (
                        <Col
                          span={8}
                          className="suggestion_tags_wrapper"
                          key={el?._id}
                        >
                          <Tag
                            className="tag_wrapper"
                            onClick={() => generateTemplateSuggestion(el)}
                          >
                            {el?.name}
                          </Tag>
                        </Col>
                      ))}
                  </Row>
                </Space>
              </Card>
            </Col>
          </Row>
        </Content>
      ),
    },
  ];

  return (
    <div className="layout templates_page">
      <Header
        dispatch={dispatch}
        organization={organization}
        organizations={props.organizations}
        auth={auth}
      />
      <UserLayout>
        <Tabs
          items={components.map((elem: TabParams, i: number) => {
            return {
              label: elem.title,
              key: String(i + 1),
              children: elem.component,
            };
          })}
        />
      </UserLayout>
    </div>
  );
};

export default WithGlobalData(TemplatesPage);
