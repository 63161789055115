import { Form, Input, Button, Card, Layout, Typography, Result } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../../services/UserService";
import ResponseError from "../../../utils/api/ResponseError";
import { useState } from "react";
import { App } from "antd";
import Content from "../../../components/Content";
import AppTexts from "../../../utils/texts/AppTexts.json";

const STEPS = {
  REGISTER: "REGISTER",
  EMAIL_CONFIRMATION_SENT: "EMAIL_CONFIRMATION_SENT",
};

const Login = () => {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [step, setStep] = useState(STEPS.REGISTER);

  const onSubmitSucceed = async (data: object) => {
    try {
      await UserService.register({ data });
      setStep(STEPS.EMAIL_CONFIRMATION_SENT);
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  return (
    <Layout className="layout auth-form-wrapper">
      <Content center>
        <Card className="card-wrapper">
          {step === STEPS.REGISTER ? (
            <Form
              name="login-form"
              initialValues={{ remember: true }}
              onFinish={onSubmitSucceed}
            >
              <Typography.Title level={3}>
                {AppTexts.auth.register.title}
              </Typography.Title>

              <Typography.Paragraph>
                {AppTexts.auth.register.description}
              </Typography.Paragraph>

              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: AppTexts.global.first_name_required,
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="First name" />
              </Form.Item>

              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: AppTexts.global.last_name_required,
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Last name" />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  { required: true, message: AppTexts.global.email_required },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: AppTexts.global.password_required,
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {AppTexts.auth.register.register}
                </Button>
              </Form.Item>
              <Typography.Text>
                {AppTexts.auth.register.already_have_account}{" "}
                <Link to={"/auth/login"}>
                  <Typography.Link>
                    {AppTexts.auth.register.login}
                  </Typography.Link>
                </Link>
              </Typography.Text>
            </Form>
          ) : (
            <Result
              status="success"
              title={AppTexts.auth.register.success_title}
              subTitle={AppTexts.auth.register.success_subtitle}
              extra={[
                <Button key="console" onClick={() => navigate("/auth/login")}>
                  {AppTexts.auth.register.login}
                </Button>,
              ]}
            />
          )}
        </Card>
      </Content>
    </Layout>
  );
};

export default Login;
