import { Card, Layout, Typography, Space, Select } from "antd";
import Content from "../../../components/Content";
import AppTexts from "../../../utils/texts/AppTexts.json";
import { useLocation } from "react-router-dom";

import { AuthUserSlice, Organization } from "../../../utils/types";
import { useDispatch } from "react-redux";
import { AuthSliceReducers } from "../../../store/slices/AuthSlice";

import { OrganizationSliceReducers } from "../../../store/slices/OrganizationSlice";

export default function SelectOrganization() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const organizations = (state?.organizations || []) as Organization[];
  const authSession = state?.session as AuthUserSlice;

  const options: { value: string; label: string }[] = organizations.map(
    (elem) => ({
      value: elem?._id,
      label: elem?.name,
    })
  );

  const handleOnChangeOrganization = (id: string) => {
    const organization = organizations?.find((elem) => elem?._id === id);
    if (organization) {
      dispatch(OrganizationSliceReducers.set(organization));
      dispatch(AuthSliceReducers.login(authSession));
    }
  };

  return (
    <Layout className="layout select-organization-page">
      <Content center>
        <Card>
          <Space direction="vertical">
            <Typography.Title level={4}>
              {AppTexts.auth.select_organization.title}
            </Typography.Title>
            <Select
              className="select-organization"
              placeholder={AppTexts.auth.select_organization.placeholder}
              onChange={handleOnChangeOrganization}
              options={[...options]}
            />
          </Space>
        </Card>
      </Content>
    </Layout>
  );
}
