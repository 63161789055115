import { App, Button, Form, Input, Space, Typography } from "antd";
import AppTexts from "../../utils/texts/AppTexts.json";
import OrganizationService from "../../services/OrganizationService";
import ResponseError from "../../utils/api/ResponseError";

export interface IEmailTemplateForm {
  onSuccessCallback?: Function;
}

export default function AddCustomContact(props: IEmailTemplateForm) {
  const { message } = App.useApp();

  const onSubmitSucceed = async (data: object) => {
    try {
      await OrganizationService.addCustomContact({
        data,
      });
      if (props?.onSuccessCallback) {
        props?.onSuccessCallback();
      }
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };
  const onSubmitFailed = () => {};

  return (
    <div>
      <Space direction="vertical">
        <Typography.Title level={2}>
          Input your personalized contact information.
        </Typography.Title>
        <Typography.Text>
          Utilizing our robust system, we offer the capability to upload
          customized contact details.
        </Typography.Text>
      </Space>

      <Form
        initialValues={{ remember: true }}
        onFinish={onSubmitSucceed}
        onFinishFailed={onSubmitFailed}
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: AppTexts.global.field_required }]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[{ required: true, message: AppTexts.global.field_required }]}
        >
          <Input type="email" placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="company"
          rules={[{ required: true, message: AppTexts.global.field_required }]}
        >
          <Input placeholder="Company" />
        </Form.Item>

        <Form.Item
          name="domain"
          rules={[{ required: true, message: AppTexts.global.field_required }]}
        >
          <Input placeholder="Domain" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="full-width">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
