import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, message } from "antd";

import Header from "../../../components/Header";
import AdminLayout from "../../../components/Layouts/AdminLayout";

import { RootState } from "../../../store/store";
import {
  AdminPromptCategoriesI,
  AdminSubject,
  TabParams,
} from "../../../utils/types";
import ResponseError from "../../../utils/api/ResponseError";
import AdminService from "../../../services/AdminService";
import AdminListSubjects from "../../../components/Admin/AdminListSubjects";
import AdminSubjectsForm from "../../../components/Admin/AdminSubjectsForm";

export default function AdminSubjectsPage() {
  const [subjects, setSubjects] = useState<AdminSubject[]>([]);
  const [promptCategories, setPromptCategories] = useState<
    AdminPromptCategoriesI[]
  >([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state?.auth?.value);

  const loadSubjects = async () => {
    try {
      const response = await AdminService.listSubjects();
      setSubjects(response.message);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const loadPromptCategories = async () => {
    try {
      const response = await AdminService.listPromptCategories();
      setPromptCategories(response.message);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const components: TabParams[] = [
    {
      title: "Subjects",
      component: (
        <AdminListSubjects
          subjects={subjects}
          loadSubjects={loadSubjects}
          loading={loading}
          promptCategories={promptCategories}
          loadPromptCategories={loadPromptCategories}
        />
      ),
    },
    {
      title: "Add Subject",
      component: <AdminSubjectsForm loadSubjects={loadSubjects} />,
    },
  ];

  useEffect(() => {
    loadSubjects();
    loadPromptCategories();
  }, []);

  return (
    <div className="layout admin">
      <Header dispatch={dispatch} auth={auth} />
      <AdminLayout>
        <Tabs
          items={components.map((elem: TabParams, i: number) => {
            return {
              label: elem.title,
              key: String(i + 1),
              children: elem.component,
            };
          })}
        />
      </AdminLayout>
    </div>
  );
}
