import { useState } from "react";
import { Col, Row, Card, Button, Layout, Typography, Space, App } from "antd";
import Content from "../../../components/Content";
import AppTexts from "../../../utils/texts/AppTexts.json";
import { useLocation } from "react-router-dom";
import constants from "../../../utils/constants";
import UserService from "../../../services/UserService";
import { AuthUserSlice } from "../../../utils/types";
import { useDispatch } from "react-redux";
import { AuthSliceReducers } from "../../../store/slices/AuthSlice";
import ResponseError from "../../../utils/api/ResponseError";
import OrganizationForm from "../../../components/Forms/OrganizationForm";
import { OrganizationSliceReducers } from "../../../store/slices/OrganizationSlice";

const STEPS = {
  LIST_TYPES: "LIST_TYPES",
  CREATE_ORGANIZATION: "CREATE_ORGANIZATION",
};

export default function OrganizationTypePage() {
  const { message } = App.useApp();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(STEPS.LIST_TYPES);

  const submit = async function (data: object) {
    const auth = state?.session as AuthUserSlice;
    const headers = {
      access_token: auth.session.access_token,
    };
    try {
      const organization = await UserService.createOrganization({
        headers,
        data,
      });
      dispatch(AuthSliceReducers.login(auth));
      dispatch(OrganizationSliceReducers.set(organization.message));
    } catch (err) {
      message.error(ResponseError.showMessage(err));
    }
  };

  return (
    <Layout className="layout organization-type-page">
      <Content center>
        {step === STEPS.CREATE_ORGANIZATION ? (
          <Card className="card-wrapper">
            <OrganizationForm
              onSubmit={(data: object) =>
                submit({ type: constants.organization.types.COMPANY, ...data })
              }
              back={() => setStep(STEPS.LIST_TYPES)}
            />
          </Card>
        ) : (
          <Space direction="vertical" size={10}>
            <Typography.Title level={2}>
              {AppTexts.auth.organization_type.title}
            </Typography.Title>
            <Row gutter={16}>
              <Col span={12}>
                <Card
                  className="organization-type-card"
                  title={AppTexts.auth.organization_type.company}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() => setStep(STEPS.CREATE_ORGANIZATION)}
                    >
                      {AppTexts.auth.organization_type.select}
                    </Button>,
                  ]}
                >
                  <Typography.Text>
                    {AppTexts.auth.organization_type.company_description}
                  </Typography.Text>
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  className="organization-type-card"
                  title={AppTexts.auth.organization_type.individual}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() =>
                        submit({
                          type: constants.organization.types.INDIVIDUAL,
                        })
                      }
                    >
                      {AppTexts.auth.organization_type.select}
                    </Button>,
                  ]}
                >
                  <Typography.Text>
                    {AppTexts.auth.organization_type.individual_description}{" "}
                  </Typography.Text>
                </Card>
              </Col>
            </Row>
          </Space>
        )}
      </Content>
    </Layout>
  );
}
