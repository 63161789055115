import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { OrganizationSlice } from "../../utils/types";

const persistConfig = {
  key: "fluxigniter-organization",
  storage,
};

const initialState: { value: OrganizationSlice } = {
  value: {
    _id: "",
    type: "",
    name: "",
    phone_number: "",
    address: "",
    website: "",
    user: "",
    live_mode: false,
  },
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<OrganizationSlice>) => {
      state.value = { ...action.payload };
    },
    updateLiveMode: (state, action: PayloadAction<{ live_mode: boolean }>) => {
      state.value.live_mode = action.payload.live_mode;
    },
    remove: (state) => {
      state.value = { ...initialState.value };
    },
  },
});

export const OrganizationSliceReducers = organizationSlice.actions;

export default persistReducer(persistConfig, organizationSlice.reducer);
