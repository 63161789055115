import React, { ReactNode } from "react";
import { Col, Card } from "antd";
import { Gutter } from "antd/es/grid/row";

interface ListCardComponentProps {
  title: string;
  description: string;
  actions?: ReactNode[];
  onClick?: () => void;
  rowGutter?: Gutter | [Gutter, Gutter];
}

const { Meta } = Card;

const ListCard: React.FC<ListCardComponentProps> = ({
  title,
  description,
  actions,
  onClick,
}) => {
  return (
    <Col xs={24} sm={12} md={8} lg={6}>
      <Card onClick={onClick} hoverable actions={actions}>
        <Meta title={title} description={description} />
      </Card>
    </Col>
  );
};

export default ListCard;
