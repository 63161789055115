import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import AppTexts from "../../../utils/texts/AppTexts.json";
import { useEffect, useState } from "react";
import ResponseError from "../../../utils/api/ResponseError";
import AdminService from "../../../services/AdminService";
import { useSearchParams } from "react-router-dom";

interface Props {
  loadGPTPrompts?: Function;
  loadDefaultGPTPrompt?: Function;
}

export default function AdminAddGPTPromptForm(props: Props) {
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const update_id = searchParams.get("id");

  const onSubmitSucceed = async (data: { name: string; ai_prompt: string }) => {
    try {
      setLoading(true);
      update_id
        ? await AdminService.updateGPTPrompt({ query: { id: update_id }, data })
        : await AdminService.addGPTPrompt({ data });
      message.success(AppTexts.admin.gpt_prompts.saved_successfully);
      !update_id && form.resetFields();
      props?.loadGPTPrompts && props?.loadGPTPrompts();
      props?.loadDefaultGPTPrompt && props?.loadDefaultGPTPrompt();
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const singleGPTPrompt = async (id: string) => {
    try {
      const response = await AdminService.singleGPTPrompt({ query: { id } });
      form.setFieldValue("name", response?.message?.name);
      form.setFieldValue("ai_prompt", response?.message?.ai_prompt);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    }
  };

  useEffect(() => {
    if (update_id) singleGPTPrompt(update_id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={40} className="addGPTPromptForm">
      <Col span={10}>
        <Card>
          <Typography.Title level={3}>
            {AppTexts.admin.gpt_prompts.title}
          </Typography.Title>

          <Space size={20} direction="vertical">
            <Typography.Text>
              {AppTexts.admin.gpt_prompts.description}
            </Typography.Text>

            <Form onFinish={onSubmitSucceed} form={form}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: AppTexts.global.field_required,
                  },
                ]}
              >
                <Input placeholder="Name - Just for you to identify" />
              </Form.Item>
              <Form.Item
                name="ai_prompt"
                rules={[
                  {
                    required: true,
                    message: AppTexts.global.field_required,
                  },
                ]}
              >
                <Input.TextArea rows={20} placeholder="Prompt" />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="submit-btn"
                >
                  {AppTexts.global.save}
                </Button>
              </Form.Item>
            </Form>
          </Space>
        </Card>
      </Col>
      <Col span={10}>
        <Card>
          <Space direction="vertical">
            <Typography.Title level={2}>
              GPT prompt template format
            </Typography.Title>

            <div>
              <Typography.Paragraph>
                {"{{ templates }}"} - The user-selected templates
              </Typography.Paragraph>
              <Typography.Paragraph>
                {"{{ templatesCount }}"} - The user-selected count of templates
                user
              </Typography.Paragraph>{" "}
              <Typography.Paragraph>
                {"{{ context }}"} - The context provided by the user
              </Typography.Paragraph>
              <Typography.Paragraph>
                {"{{ contactName }}"} - The name of the contact
              </Typography.Paragraph>
            </div>
          </Space>
        </Card>
      </Col>
    </Row>
  );
}
