import { App, Button, Form, FormInstance, Input, Typography } from "antd";
import AppTexts from "../../../utils/texts/AppTexts.json";
import OrganizationService from "../../../services/OrganizationService";
import ResponseError from "../../../utils/api/ResponseError";
import { EmailTemplate } from "../../../utils/types";
import ReactQuillComponent from "../../ReactQuillComponent";
import { useState } from "react";

export interface IEmailTemplateForm {
  onSuccessCallback?: Function;
  edit?: boolean;
  template?: EmailTemplate;
  form?: FormInstance<any>;
}

export default function EmailTemplateForm(props: IEmailTemplateForm) {
  const [content, setContent] = useState(props?.template?.content || "")

  const { message } = App.useApp();

  const onSubmitSucceed = async (data: EmailTemplate) => {
    try {
      const query = { id: props?.template?._id };
      const response = props?.edit
        ? await OrganizationService.updateEmailTemplate({ data, query })
        : await OrganizationService.createEmailTemplate({
          data,
        });
      if (props?.onSuccessCallback) props.onSuccessCallback(response);
      message.success(
        `Template ${props?.edit ? "updated" : "created"} successfully`
      );
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  const onSubmitFailed = () => { };

  return (
    <div>
      {props?.edit && (
        <Typography.Title level={2}>
          {AppTexts.organization_templates.edit_template}
        </Typography.Title>
      )}
      <Form
        key={props?.template?._id}
        form={props?.form}
        initialValues={{
          name: props?.template?.name,
          subject: props?.template?.subject,
          content: content,
        }}
        onFinish={onSubmitSucceed}
        onFinishFailed={onSubmitFailed}
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: AppTexts.global.field_required }]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item
          name="content"
          rules={[{ required: true, message: AppTexts.global.field_required }]}
        >
          <ReactQuillComponent
            content={content}
            onChange={setContent}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="full-width">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
