import AdminLayout from "../../../components/Layouts/AdminLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Header from "../../../components/Header";
import Content from "../../../components/Content";
import { Button, Card, Form, Input, Space, Typography, message } from "antd";
import AppTexts from "../../../utils/texts/AppTexts.json";
import { useState } from "react";
import ResponseError from "../../../utils/api/ResponseError";
import AdminService from "../../../services/AdminService";

export default function AdminQuickGPT() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const auth = useSelector((state: RootState) => state?.auth?.value);
  const [loading, setLoading] = useState(false);

  const onSubmitSucceed = async (data: { ai_prompt: string }) => {
    try {
      setLoading(true);
      const response = await AdminService.quickGPT({
        data: { content: data?.ai_prompt },
      });
      form.setFieldValue(
        "gpt_response",
        response?.message || "An error occurred. Please try again later!"
      );
      message.success("GPT response generated successfully");
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="layout admin-quick-gpt-page">
      <Header dispatch={dispatch} auth={auth} />
      <AdminLayout>
        <Content center>
          <Card>
            <Space direction="vertical" size={20}>
              <Space direction="vertical">
                <Typography.Title level={3}>
                  Explore GPT instantly!
                </Typography.Title>

                <Typography.Text>
                  This page has been designed for administrators who wish to
                  instantly test GPT prompts without navigating through the user
                  process.
                </Typography.Text>
              </Space>

              <Form onFinish={onSubmitSucceed} form={form}>
                <Form.Item
                  name="ai_prompt"
                  rules={[
                    {
                      required: true,
                      message: AppTexts.global.field_required,
                    },
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Prompt" />
                </Form.Item>
                <Form.Item name="gpt_response">
                  <Input.TextArea
                    rows={10}
                    placeholder="GPT response (This will be filled when GPT has responded)"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    disabled={loading}
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Space>
          </Card>
        </Content>
      </AdminLayout>
    </div>
  );
}
