import Header from "../../components/Header";
import CostumeContactProfile from "../../components/Contacts/CostumeContactProfile";
import UserLayout from "../../components/Layouts/UserLayout";
import { WithGlobalData } from "../../utils/hooks/withGlobalData";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  CustomContact,
  HubSpotContactResponseType,
  WithGlobalDataProps,
} from "../../utils/types";
import { useSearchParams } from "react-router-dom";
import OrganizationService from "../../services/OrganizationService";
import { message } from "antd";
import ResponseError from "../../utils/api/ResponseError";
import { useEffect, useState } from "react";
import HubspotContactProfile from "../../components/Contacts/HubspotContactProfile";
import HubSpotService from "../../services/HubSpotService";
import Loading from "../../components/Loading";

interface IOrganizationContactsPage extends WithGlobalDataProps {}

const OrganizationContactProfile = (props: IOrganizationContactsPage) => {
  const [searchParams] = useSearchParams();
  const [costumeContactProfile, setContactProfile] = useState<CustomContact>();
  const [hubSpotProfile, setHubspotContactProfile] = useState<HubSpotContactResponseType>();
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state: RootState) => state.auth.value);
  const id = searchParams.get("id");
  const hubspot = searchParams.get("hubspot");
  const organization = useSelector(
    (state: RootState) => state.organization.value
  );
  const dispatch = useDispatch();

  const loadContactProfile = async (id: string | null) => {
    try {
      setLoading(true)
      const query = {
        _id: id,
      };
      const response = await OrganizationService.getContactProfile({ query });
      setContactProfile(response.message);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false)
    }
  };

  const loadHubspotContactProfile = async (id: string) => {
    try {
      setLoading(true)
      const query = { id };
      const response = await HubSpotService.contactProfile({ query });
      console.log(response)
      setHubspotContactProfile(response?.message);
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }finally{
      setLoading(false)
    }
  };

  useEffect(() => {
    if (id) {
      if (hubspot === "true") {
        loadHubspotContactProfile(id);
      } else {
        loadContactProfile(id);
      }
    }
  }, []);

  return (
    <div className="layout hubspot-contacts-page">
      <Header
        dispatch={dispatch}
        organization={organization}
        organizations={props.organizations}
        auth={auth}
      />
      <UserLayout>
      <div>
      {loading ? (
        <Loading />
      ) : (
        hubspot === "false" ? (
          <CostumeContactProfile profile={costumeContactProfile as CustomContact} />
        ) : (
          <HubspotContactProfile profile={hubSpotProfile as HubSpotContactResponseType} />
        )
      )}
    </div>
      </UserLayout>
    </div>
  );
};
export default WithGlobalData(OrganizationContactProfile);
