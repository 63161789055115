import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import { RootState } from "../../store/store";
import { WithGlobalData } from "../../utils/hooks/withGlobalData";
import { TabParams, WithGlobalDataProps } from "../../utils/types";
import UserLayout from "../../components/Layouts/UserLayout";
import { Tabs } from "antd";
import AppTexts from "../../utils/texts/AppTexts.json";
import EmailIntegration from "../../components/OrganizationEmail/Integration";
import EmailHistory from "../../components/OrganizationEmail/History";
import QuickEmail from "../../components/OrganizationEmail/QuickEmail";

interface Props extends WithGlobalDataProps {}

function OrganizationEmailPage(props: Props) {
  const auth = useSelector((state: RootState) => state.auth.value);
  const organization = useSelector(
    (state: RootState) => state.organization.value
  );
  const dispatch = useDispatch();

  const organization_email_page = AppTexts.organization_email_page;

  const components: TabParams[] = [
    {
      title: organization_email_page.steps.integration,
      component: <EmailIntegration {...props} />,
    },
    {
      title: organization_email_page.steps.history,
      component: <EmailHistory />,
    },
    {
      title: organization_email_page.steps.quick,
      component: <QuickEmail />,
    },
  ];

  return (
    <div className="layout organization-email-page">
      <Header
        dispatch={dispatch}
        organization={organization}
        organizations={props.organizations}
        auth={auth}
      />
      <UserLayout>
        <>
          <Tabs
            items={components.map((elem, i) => {
              return {
                label: elem.title,
                key: String(i + 1),
                children: elem.component,
              };
            })}
            destroyInactiveTabPane
          />
        </>
      </UserLayout>
    </div>
  );
}

export default WithGlobalData(OrganizationEmailPage);
