import ForgotPasswordPage from "../pages/Auth/ForgotPasswordPage";
import LoginPage from "../pages/Auth/LoginPage";
import RegisterPage from "../pages/Auth/RegisterPage";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import PrivateRoute from "./PrivateRoute";
import TwoFactorAuthPage from "../pages/Auth/TwoFactorAuthPage";
import NonAuthRoute from "./NonAuthRoute";
import VerifyAccountPage from "../pages/Auth/VerifyAccountPage";
import ResetPasswordPage from "../pages/Auth/ResetPasswordPage";
import OrganizationTypePage from "../pages/Auth/OrganizationTypePage";
import TemplatesPage from "../pages/TemplatesPage";
import ManageOrganizationPage from "../pages/ManageOrganizationPage";
import SelectOrganization from "../pages/Auth/SelectOrganizationPage";
import ProfilePage from "../pages/User/ProfilePage";
import HubspotOAuthCallbackPage from "../pages/HubspotOAuthCallbackPage";
import OrganizationContactsPage from "../pages/OrganizationContactsPage";
import GmailOAuthCallbackPage from "../pages/GmailOAuthCallbackPage";
import AdminRole from "../utils/hooks/AdminRole";
import UserRole from "../utils/hooks/UserRole";
import AdminProfilePage from "../pages/Admin/AdminProfilePage";
import AdminGPTPromptsPage from "../pages/Admin/AdminGPTPromptsPage";
import AdminGPTUpdatePromptPage from "../pages/Admin/AdminGPTUpdatePromptPage";
import AdminQuickGPT from "../pages/Admin/AdminQuickGPT";
import OrganizationEmailPage from "../pages/OrganizationEmailPage";
import AdminTemplatesPage from "../pages/Admin/AdminTemplatesPage";
import AdminTemplateSuggestions from "../pages/Admin/AdminTemplateSuggestions";
import OrganizationContactProfile from "../pages/OrganizationContactProfile";
import MediaLibraryPage from "../pages/MediaLibraryPage";
import AdminSubjectsPage from "../pages/Admin/AdminSubjectsPage";
import AdminUpdateSubjectsPage from "../pages/Admin/AdminUpdateSubjectsPage";

export default function RoutesComponent() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <UserRole>
              <HomePage />
            </UserRole>
          </PrivateRoute>
        }
      />

      <Route path="/user">
        <Route
          path="profile"
          element={
            <PrivateRoute>
              <UserRole>
                <ProfilePage />
              </UserRole>
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/organization">
        <Route
          path="templates"
          element={
            <PrivateRoute>
              <UserRole>
                <TemplatesPage />
              </UserRole>
            </PrivateRoute>
          }
        />
        <Route
          path="manage"
          element={
            <PrivateRoute>
              <UserRole>
                <ManageOrganizationPage />
              </UserRole>
            </PrivateRoute>
          }
        />
        <Route
          path="contacts"
          element={
            <PrivateRoute>
              <UserRole>
                <OrganizationContactsPage />
              </UserRole>
            </PrivateRoute>
          }
        />
        <Route
          path="contact/profile"
          element={
            <PrivateRoute>
              <UserRole>
                <OrganizationContactProfile />
              </UserRole>
            </PrivateRoute>
          }
        />
        <Route
          path="email"
          element={
            <PrivateRoute>
              <UserRole>
                <OrganizationEmailPage />
              </UserRole>
            </PrivateRoute>
          }
        />
        <Route
          path="media-library"
          element={
            <PrivateRoute>
              <UserRole>
                <MediaLibraryPage />
              </UserRole>
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/auth">
        <Route
          path="login"
          element={
            <NonAuthRoute>
              <LoginPage />
            </NonAuthRoute>
          }
        />
        <Route
          path="register"
          element={
            <NonAuthRoute>
              <RegisterPage />
            </NonAuthRoute>
          }
        />
        <Route
          path="forgot-password"
          element={
            <NonAuthRoute>
              <ForgotPasswordPage />
            </NonAuthRoute>
          }
        />
        <Route
          path="reset-password"
          element={
            <NonAuthRoute>
              <ResetPasswordPage />
            </NonAuthRoute>
          }
        />
        <Route
          path="two-factor-auth"
          element={
            <NonAuthRoute>
              <TwoFactorAuthPage />
            </NonAuthRoute>
          }
        />
        <Route
          path="verify-account"
          element={
            <NonAuthRoute>
              <VerifyAccountPage />
            </NonAuthRoute>
          }
        />
        <Route
          path="organization-type"
          element={
            <NonAuthRoute>
              <OrganizationTypePage />
            </NonAuthRoute>
          }
        />
        <Route
          path="select-organization"
          element={
            <NonAuthRoute>
              <SelectOrganization />
            </NonAuthRoute>
          }
        />
      </Route>

      <Route path="/hubspot">
        <Route
          path="oauth-callback"
          element={
            <PrivateRoute>
              <UserRole>
                <HubspotOAuthCallbackPage />
              </UserRole>
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/gmail">
        <Route
          path="oauthcallback"
          element={
            <PrivateRoute>
              <UserRole>
                <GmailOAuthCallbackPage />
              </UserRole>
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/admin">
        {/* <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <AdminRole>
                <AdminDashboardPage />
              </AdminRole>
            </PrivateRoute>
          }
        /> */}
        <Route
          path="templates"
          element={
            <PrivateRoute>
              <AdminRole>
                <AdminTemplatesPage />
              </AdminRole>
            </PrivateRoute>
          }
        />
        <Route
          path="suggestions"
          element={
            <PrivateRoute>
              <AdminRole>
                <AdminTemplateSuggestions />
              </AdminRole>
            </PrivateRoute>
          }
        />
        <Route
          path="profile"
          element={
            <PrivateRoute>
              <AdminRole>
                <AdminProfilePage />
              </AdminRole>
            </PrivateRoute>
          }
        />
        <Route
          path="gpt-prompts"
          element={
            <PrivateRoute>
              <AdminRole>
                <AdminGPTPromptsPage />
              </AdminRole>
            </PrivateRoute>
          }
        />

        <Route
          path="quick-gpt"
          element={
            <PrivateRoute>
              <AdminRole>
                <AdminQuickGPT />
              </AdminRole>
            </PrivateRoute>
          }
        />

        <Route
          path="update-gpt-prompt"
          element={
            <PrivateRoute>
              <AdminRole>
                <AdminGPTUpdatePromptPage />
              </AdminRole>
            </PrivateRoute>
          }
        />

        <Route
          path="subjects"
          element={
            <PrivateRoute>
              <AdminRole>
                <AdminSubjectsPage />
              </AdminRole>
            </PrivateRoute>
          }
        />

        <Route
          path="update-subject"
          element={
            <PrivateRoute>
              <AdminRole>
                <AdminUpdateSubjectsPage />
              </AdminRole>
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
  );
}
