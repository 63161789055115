import {
  HomeOutlined,
  UserOutlined,
  SnippetsOutlined,
  CheckOutlined,
  DiffOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Menu, Layout } from "antd";
import { Link, useLocation } from "react-router-dom";
import UserService from "../../services/UserService";
import {
  AuthUserSlice,
  Organization,
  OrganizationSlice,
} from "../../utils/types";
import { AuthSliceReducers } from "../../store/slices/AuthSlice";
import { Dispatch } from "react";
import { AnyAction } from "redux";
import { OrganizationSliceReducers } from "../../store/slices/OrganizationSlice";
import constants from "../../utils/constants";

interface IHeaderProps {
  auth: AuthUserSlice;
  dispatch: Dispatch<AnyAction>;
  organization?: OrganizationSlice;
  organizations?: Organization[];
}

export default function Header(props: IHeaderProps) {
  const { pathname } = useLocation();
  const isAdminRole = props?.auth?.user?.role === constants.user.roles.ADMIN;

  const Logout = () => {
    UserService.logout();
    props.dispatch(AuthSliceReducers.logout());
    props.dispatch(OrganizationSliceReducers.remove());
  };

  const switchOrganization = (organization: Organization) => {
    props?.dispatch(OrganizationSliceReducers.set(organization));
    window.location.reload();
  };

  const individualOrganization = props.organizations?.find(
    (org: Organization) => org.type === constants.organization.types.INDIVIDUAL
  );

  return (
    <Layout.Header className="header">
      <Link to="/">
        <Menu mode="horizontal" theme="dark" defaultSelectedKeys={["2"]}>
          <Menu.Item
            className={`menu-item ${pathname === "/" && "active"}`}
            icon={<HomeOutlined />}
          >
            Home
          </Menu.Item>
        </Menu>
      </Link>
      <Menu mode="horizontal" theme="dark">
        {!isAdminRole && (
          <Menu.SubMenu
            className={`menu-item ${
              pathname === "/organization/templates" && "active"
            } ${pathname === "/organization/manage" && "active"}`}
            title="Organization"
            icon={<SnippetsOutlined />}
          >
            {props?.organizations?.length &&
              props?.organizations?.length > 0 &&
              props?.organizations?.map(
                (organization: Organization, index: number) =>
                  organization.type ===
                    constants.organization.types.COMPANY && (
                    <Menu.Item
                      disabled={organization?._id === props.organization?._id}
                      key={index}
                      onClick={() => {
                        if (organization?._id !== props.organization?._id) {
                          switchOrganization(organization);
                        }
                      }}
                      icon={
                        organization?._id === props.organization?._id ? (
                          <CheckOutlined />
                        ) : (
                          <DiffOutlined />
                        )
                      }
                    >
                      {organization.name}
                    </Menu.Item>
                  )
              )}

            {individualOrganization && (
              <Link to="/organization/manage">
                <Menu.Item
                  disabled={
                    individualOrganization?._id === props.organization?._id
                  }
                  onClick={() => {
                    if (
                      individualOrganization?._id !== props.organization?._id
                    ) {
                      switchOrganization(individualOrganization);
                    }
                  }}
                  icon={
                    individualOrganization?._id === props.organization?._id ? (
                      <CheckOutlined />
                    ) : (
                      <DiffOutlined />
                    )
                  }
                >
                  Individual
                </Menu.Item>
              </Link>
            )}

            <Link to="/organization/manage">
              <Menu.Item icon={<PlusOutlined />}>Add new</Menu.Item>
            </Link>
          </Menu.SubMenu>
        )}
        <Menu.SubMenu
          className="menu-item"
          title={`${props.auth.user.first_name} ${props.auth.user.last_name}`}
          icon={<UserOutlined />}
        >
          <Link to={`/${isAdminRole ? `admin` : "user"}/profile`}>
            <Menu.Item className="menu-item" icon={<UserOutlined />}>
              Profile
            </Menu.Item>
          </Link>

          {!isAdminRole && (
            <Link to="/organization/manage">
              <Menu.Item className="menu-item" icon={<UserOutlined />}>
                Organizations
              </Menu.Item>
            </Link>
          )}

          <Menu.Item
            className="menu-item"
            onClick={Logout}
            icon={<UserOutlined />}
          >
            Logout
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Layout.Header>
  );
}
