import { Endpoint } from "../utils/api/Endpoints";
import {
  AdminTemplateSuggestion,
  ApiFetchRequestOptions,
  BaseApiResponse,
  CustomContact,
  DashboardStatistics,
  EmailTemplate,
  GenerateEmailSubjectResponse,
  GenerateEmailTemplateResponse,
  MediaLibrary,
  Organization,
  OrganizationEmailEvent,
  S3Metadata,
  SuggestedTemplate,
  UserSubject,
} from "../utils/types";
import ApiProvider from "./AbstractApiProvider";

class OrganizationService extends ApiProvider {
  public async createEmailTemplate(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<EmailTemplate>> {
    return this.callApi(
      Endpoint.user.organizations.emailTemplates.create,
      options
    );
  }

  public async updateEmailTemplate(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<boolean>> {
    return this.callApi(
      Endpoint.user.organizations.emailTemplates.update,
      options
    );
  }

  public async updateOrganization(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<boolean>> {
    return this.callApi(Endpoint.user.organizations.update, options);
  }

  public async listEmailTemplates(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<EmailTemplate[]>> {
    return this.callApi(
      Endpoint.user.organizations.emailTemplates.list,
      options
    );
  }

  public async deleteEmailTemplate(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<EmailTemplate>> {
    return this.callApi(
      Endpoint.user.organizations.emailTemplates.delete,
      options
    );
  }

  public async generateEmailTemplate(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<GenerateEmailTemplateResponse>> {
    return this.callApi(
      Endpoint.user.organizations.emailTemplates.generate,
      options
    );
  }

  public async generateEmailSubject(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<GenerateEmailSubjectResponse>> {
    return this.callApi(
      Endpoint.user.organizations.emailTemplates.generateSubject,
      options
    );
  }

  public async sendGeneratedEmail(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<any>> {
    return this.callApi(
      Endpoint.user.organizations.emailTemplates.send,
      options
    );
  }

  public async dashboardStatistics(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<DashboardStatistics>> {
    return this.callApi<BaseApiResponse<DashboardStatistics>>(
      Endpoint.user.organizations.dashboard.statistics,
      options
    );
  }

  public async addCustomContact(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<CustomContact>> {
    return this.callApi<BaseApiResponse<CustomContact>>(
      Endpoint.user.organizations.addCustomContact,
      options
    );
  }

  public async loadCustomContacts(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<CustomContact[]>> {
    return this.callApi<BaseApiResponse<CustomContact[]>>(
      Endpoint.user.organizations.loadCustomContacts,
      options
    );
  }

  public async getOrganizations(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<Organization[]>> {
    return this.callApi<BaseApiResponse<Organization[]>>(
      Endpoint.user.organizations.list,
      options
    );
  }

  public async deleteOrganization(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<Organization>> {
    return this.callApi(Endpoint.user.organizations.delete, options);
  }
  public async getContactProfile(
    options: ApiFetchRequestOptions= {}
    ): Promise<BaseApiResponse<CustomContact>> {
      return this.callApi(Endpoint.user.organizations.getContactProfile, options);
    }
  
  public async updateLiveMode(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<Organization>> {
    return this.callApi(Endpoint.user.organizations.updateLiveMode, options);
  }

  public async getEmailsEventsHistory(options: ApiFetchRequestOptions = {}) {
    return this.callApi<
      BaseApiResponse<{
        emailEvents: OrganizationEmailEvent[];
        eventsReplied: { [id: string]: boolean };
      }>
    >(Endpoint.user.organizations.emailsEventsHistory, options);
  }

  public async loadTemplateSuggestions(options: ApiFetchRequestOptions = {}) {
    return this.callApi<BaseApiResponse<AdminTemplateSuggestion[]>>(
      Endpoint.user.organizations.listTemplateSuggestions,
      options
    );
  }

  public async generateTemplateSuggestion(
    options: ApiFetchRequestOptions = {}
  ) {
    return this.callApi<BaseApiResponse<string>>(
      Endpoint.user.organizations.emailTemplates.generateSuggestion,
      options
    );
  }

  public async listSuggestedTemplates(options: ApiFetchRequestOptions = {}) {
    return this.callApi<BaseApiResponse<SuggestedTemplate[]>>(
      Endpoint.user.organizations.emailTemplates.listSuggestedTemplates,
      options
    );
  }

  public async listMediaKeys(options: ApiFetchRequestOptions = {}) {
    return this.callApi<BaseApiResponse<MediaLibrary>>(
      Endpoint.user.organizations.mediaLibrary.list,
      options
    );
  }

  public async mediaFile(options: ApiFetchRequestOptions = {}) {
    return this.callApi<BaseApiResponse<Uint8Array>>(
      Endpoint.user.organizations.mediaLibrary.list,
      options
    );
  }

  public async uploadFiles(options: ApiFetchRequestOptions = {}) {
    return this.callApi<BaseApiResponse<boolean>>(
      Endpoint.user.organizations.mediaLibrary.upload,
      options
    );
  }

  public async deleteFile(options: ApiFetchRequestOptions = {}) {
    return this.callApi<BaseApiResponse<S3Metadata>>(
      Endpoint.user.organizations.mediaLibrary.delete,
      options
    );
  }

  public async listSubjects(options: ApiFetchRequestOptions = {}) {
    return this.callApi<BaseApiResponse<UserSubject[]>>(
      Endpoint.user.organizations.subjects.list,
      options
    );
  }

  public async updateSubject(options: ApiFetchRequestOptions = {}) {
    return this.callApi<BaseApiResponse<UserSubject>>(
      Endpoint.user.organizations.subjects.update,
      options
    );
  }

  public async addSubject(options: ApiFetchRequestOptions = {}) {
    return this.callApi<BaseApiResponse<UserSubject>>(
      Endpoint.user.organizations.subjects.add,
      options
    );
  }

  public async deleteSubject(options: ApiFetchRequestOptions = {}) {
    return this.callApi<BaseApiResponse<boolean>>(
      Endpoint.user.organizations.subjects.delete,
      options
    );
  }
}

export default new OrganizationService();
