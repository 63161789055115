import { useEffect, useState } from "react";
import { OrganizationEmailEvent } from "../../../utils/types";
import OrganizationService from "../../../services/OrganizationService";
import { Card, Descriptions, Space, Typography, message, Tag } from "antd";
import ResponseError from "../../../utils/api/ResponseError";
import Content from "../../../components/Content";
import Loading from "../../../components/Loading";
import AppTexts from "../../../utils/texts/AppTexts.json";

function EmailHistory() {
  const { emailHistoryPage } = AppTexts;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<{
    emailEvents: OrganizationEmailEvent[];
    eventsReplied: { [id: string]: boolean };
  }>({ eventsReplied: {}, emailEvents: [] });

  const loadEmailsEventsHistory = async () => {
    try {
      const response = await OrganizationService.getEmailsEventsHistory();
      setData(response?.message);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadEmailsEventsHistory();
  }, []);

  return (
    <div className="layout email-history-wrapper">
      {/* <pre>{JSON.stringify(data, null, 3)}</pre> */}
      <div>
        {loading ? (
          <Loading />
        ) : data?.emailEvents?.length > 0 ? (
          <Content center>
            <Card>
              <Typography.Title level={3}>
                {emailHistoryPage.title}
              </Typography.Title>
              <Space direction="vertical" size={15}>
                <Typography.Paragraph>
                  {emailHistoryPage.description}{" "}
                </Typography.Paragraph>
                {data?.emailEvents?.map((elem) => (
                  <Descriptions
                    className="descriptions-wrapper"
                    key={elem?._id?.toString()}
                    title={elem?.to?.email}
                  >
                    <Descriptions.Item
                      label={emailHistoryPage.form_labels.from}
                    >
                      {elem?.from?.email}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={emailHistoryPage.form_labels.subject}
                    >
                      {elem?.subject}
                    </Descriptions.Item>

                    <Descriptions.Item>
                      {elem?.opened ? (
                        <Tag color="green">
                          Opened {elem?.opened_count}{" "}
                          {elem?.opened_count === 1 ? "time" : "times"}
                        </Tag>
                      ) : (
                        <Tag color="red">Not opened</Tag>
                      )}
                    </Descriptions.Item>

                    <Descriptions.Item
                      label={emailHistoryPage.form_labels.date_time}
                    >
                      {new Date(elem?.createdAt)?.toLocaleDateString()} -{" "}
                      {new Date(elem?.createdAt)?.toLocaleTimeString()}
                    </Descriptions.Item>
                    <Descriptions.Item label={emailHistoryPage.form_labels.to}>
                      {elem?.to?.email}
                    </Descriptions.Item>
                    <Descriptions.Item>
                      {data?.eventsReplied?.[elem?._id?.toString()] ? (
                        <Tag color="green">Replied</Tag>
                      ) : (
                        <Tag color="red">Not replied</Tag>
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                ))}
              </Space>
            </Card>
          </Content>
        ) : (
          <Content center>
            <Card className="no-history-wrapper">
              <Typography.Title level={3}>
                {emailHistoryPage.title}
              </Typography.Title>
              <Typography.Paragraph className="description">
                {emailHistoryPage.description}{" "}
              </Typography.Paragraph>
            </Card>
          </Content>
        )}
      </div>
    </div>
  );
}

export default EmailHistory;
