import { useState } from "react";
import {
  App,
  Button,
  Card,
  Form,
  Input,
  Space,
  Typography,
} from "antd";

import AppText from "../../../utils/texts/AppTexts.json";
import ResponseError from "../../../utils/api/ResponseError";
import UserService from "../../../services/UserService";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { AuthSliceReducers } from "../../../store/slices/AuthSlice";
import { useDispatch } from "react-redux";

export default function ProfileInfoForm() {
  const auth = useSelector((state: RootState) => state.auth.value);
  const { user } = auth;
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();
  const dispatch = useDispatch();

  const onSubmitSucceed = async (data: object) => {
    setLoading(true);
    try {
      const response = await UserService.updateProfile({ data });
      if (response.message) {
        message.success(AppText.profile_page.user_info.profile_updated);
        const updatedUser = await UserService.getProfile();
        dispatch(AuthSliceReducers.updateUser(updatedUser.message));
      }
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="profile-info-form">
      <Typography.Title level={3}>
        {AppText.auth.profile_page.steps.personal_details.title}
      </Typography.Title>
      <Space direction="vertical">
        <Typography.Paragraph>
          {AppText.auth.profile_page.steps.personal_details.content}
        </Typography.Paragraph>

        <Form
          name="update-profile-form"
          layout="vertical"
          onFinish={onSubmitSucceed}
        >
          <Form.Item label="Email" name="email">
            <Input defaultValue={user.email} disabled={true} />
          </Form.Item>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              { required: true, message: AppText.global.first_name_required },
            ]}
            initialValue={user.first_name}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[
              { required: true, message: AppText.global.last_name_required },
            ]}
            initialValue={user.last_name}
          >
            <Input />
          </Form.Item>

          <div>
            <Button loading={loading} type="primary" htmlType="submit">
              {AppText.global.save}
            </Button>
          </div>
        </Form>
      </Space>
    </Card>
  );
}
