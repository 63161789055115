import { Form, Input, Button, Card, Layout, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import UserService from "../../../services/UserService";
import {
  UserLoginSessionResponse,
  UserLoginTFAResponse,
} from "../../../utils/types";
import ResponseError from "../../../utils/api/ResponseError";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { App } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useDispatch } from "react-redux";
import { AuthSliceReducers } from "../../../store/slices/AuthSlice";
import Content from "../../../components/Content";
import AppTexts from "../../../utils/texts/AppTexts.json";
import { OrganizationSliceReducers } from "../../../store/slices/OrganizationSlice";
import constants from "../../../utils/constants";

const Login = () => {
  const dispatch = useDispatch();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth.value);
  const [loading, setLoading] = useState(false);

  const onSubmitSucceed = async (data: object) => {
    data = { ...data, tfa_token: auth.tfaAuthToken };
    setLoading(true);
    try {
      const response = await UserService.login({ data });
      if ((response as UserLoginTFAResponse).message?.tfa_token) {
        const tfa_response = response as UserLoginTFAResponse;
        navigate(
          `/auth/two-factor-auth?token=${tfa_response.message.tfa_token}`
        );
      } else {
        const session_response = response as UserLoginSessionResponse;
        if (
          session_response?.message?.user?.role === constants.user.roles.ADMIN
        ) {
          dispatch(AuthSliceReducers.login(session_response.message));
          return;
        }
        const organizations = await UserService.listOrganizations({
          headers: {
            access_token: session_response.message.session.access_token,
          },
        });
        if (organizations?.message?.length === 0) {
          navigate(`/auth/organization-type`, {
            state: { session: session_response.message },
          });
        } else {
          if (organizations?.message?.length === 1) {
            dispatch(OrganizationSliceReducers.set(organizations.message?.[0]));
            dispatch(AuthSliceReducers.login(session_response.message));
          } else {
            navigate(`/auth/select-organization`, {
              state: {
                organizations: organizations?.message,
                session: session_response.message,
              },
            });
          }
        }
      }
      setLoading(false);
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
      setLoading(false);
    }
  };

  return (
    <Layout className="layout auth-form-wrapper">
      <Content center>
        <Card className="card-wrapper">
          <Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={onSubmitSucceed}
          >
            <Typography.Title className="text-center" level={3}>
              {AppTexts.auth.login.title}
            </Typography.Title>

            <Typography.Paragraph>
              {AppTexts.auth.login.description}
            </Typography.Paragraph>

            <Form.Item
              name="email"
              rules={[
                { required: true, message: AppTexts.global.email_required },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={AppTexts.auth.login.inputs.email_placeholder}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: AppTexts.global.password_required },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder={AppTexts.auth.login.inputs.password_placeholder}
              />
            </Form.Item>

            <Form.Item>
              <Link
                className="forgot-password-link"
                to={"/auth/forgot-password"}
              >
                {AppTexts.auth.login.forgot_password}
              </Link>
            </Form.Item>

            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {AppTexts.auth.login.login}
              </Button>
            </Form.Item>

            <Typography.Text>
              {AppTexts.auth.login.no_account}{" "}
              <Link to={"/auth/register"}>
                <Typography.Link>
                  {AppTexts.auth.login.register}
                </Typography.Link>
              </Link>
            </Typography.Text>
          </Form>
        </Card>
      </Content>
    </Layout>
  );
};

export default Login;
