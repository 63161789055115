import { Layout } from "antd";
import { CSSProperties } from "react";

interface ContentProps {
  center?: boolean;
  style?: CSSProperties | undefined;
  children: React.ReactElement;
}

export default function Content(props: ContentProps) {
  let centerStyle = {};
  if (props.center) {
    centerStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
  }
  return (
    <Layout.Content style={{ ...props.style, ...centerStyle }}>
      {props.children}
    </Layout.Content>
  );
}
