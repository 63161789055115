import { Alert, Spin } from "antd";

interface LoadingProps {
  title?: string;
  description?: string;
}

export default function Loading(props: LoadingProps) {
  return (
    <Spin spinning={true}>
      <Alert
        message={props?.title || "Loading..."}
        description={props?.description || "Loading..."}
        type="info"
      />
    </Spin>
  );
}
