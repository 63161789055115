import { Card, Col, Popconfirm, Row, Typography } from "antd";
import { AdminTemplate } from "../../../utils/types";
import Loading from "../../Loading";
import Meta from "antd/es/card/Meta";
import Content from "../../Content";
import AppTexts from "../../../utils/texts/AppTexts.json";

interface Props {
  loading: boolean;
  templates: AdminTemplate[];
  deleteTemplate: Function;
  showEditModal: Function;
}

export default function AdminListTemplates(props: Props) {
  return (
    <div className="admin-templates-page">
      {props?.loading && <Loading />}
      {!props?.loading && props?.templates?.length === 0 && (
        <Content center>
          <Card>
            <Typography.Title level={3}>
              {AppTexts.admin.templates_page.list.title}
            </Typography.Title>
            <Typography.Text>
              {AppTexts.admin.templates_page.list.description}
            </Typography.Text>
          </Card>
        </Content>
      )}
      {!props?.loading && props?.templates?.length > 0 && (
        <Row gutter={[16, 16]}>
          {props?.templates?.map((template) => (
            <Col
              className="template-col-wrapper"
              xs={24}
              sm={12}
              md={8}
              lg={6}
              key={template?._id}
            >
              <Card
                hoverable
                actions={[
                  <span onClick={() => props?.showEditModal(template)}>
                    Edit
                  </span>,
                  <Popconfirm
                    title="Delete the template?"
                    description="Are you sure to delete this template?"
                    onConfirm={() => props?.deleteTemplate(template._id)}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <span>Delete</span>
                  </Popconfirm>,
                ]}
              >
                <Meta
                  title={template?.name}
                  description={template?.content?.substring(0, 100) + "..."}
                />
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}
