import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  message,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Header from "../../components/Header";
import UserLayout from "../../components/Layouts/UserLayout";
import { useSelector } from "react-redux";
import { RootState, store } from "../../store/store";
import { useDispatch } from "react-redux";
import UserService from "../../services/UserService";
import { Organization, WithGlobalDataProps } from "../../utils/types";
import AppTexts from "../../utils/texts/AppTexts.json";
import { OrganizationSliceReducers } from "../../store/slices/OrganizationSlice";
import OrganizationForm from "../../components/Forms/OrganizationForm";
import { WithGlobalData } from "../../utils/hooks/withGlobalData";
import ResponseError from "../../utils/api/ResponseError";
import constants from "../../utils/constants";
import { GlobalSliceReducers } from "../../store/slices/GlobalSlice";
import OrganizationService from "../../services/OrganizationService";
import Loading from "../../components/Loading";

interface IManageOrganizationPage extends WithGlobalDataProps {}

const ManageOrganizationPage = (props: IManageOrganizationPage) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const stateOrganization = useSelector(
    (state: RootState) => state.organization.value
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const loadOrganizations = async () => {
    try {
      const response = await UserService.listOrganizations();
      setOrganizations(response?.message);
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const switchOrganization = (organization: Organization) => {
    dispatch(OrganizationSliceReducers.set(organization));
    window.location.reload();
  };

  const addOrganization = () => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: (
          <OrganizationForm
            onSubmit={() => {
              loadOrganizations();
              store.dispatch(GlobalSliceReducers.closeModal());
            }}
            back={() => {}}
          />
        ),
      })
    );
  };

  const deleteOrganization = async (organization: Organization) => {
    try {
      const query = {
        _id: organization?._id,
      };
      await OrganizationService.deleteOrganization({
        query,
      });
      loadOrganizations();
      message.success("Organization deleted successfully");
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  const showEditModal = (organization: Organization) => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: (
          <OrganizationForm
            organization={organization}
            onSubmit={() => {
              loadOrganizations();
              store.dispatch(GlobalSliceReducers.closeModal());
            }}
            back={() => {}}
          />
        ),
      })
    );
  };

  useEffect(() => {
    loadOrganizations();
  }, []);

  return (
    <div className="layout manage-organization-page">
      <Header
        dispatch={dispatch}
        organization={stateOrganization}
        auth={auth}
        organizations={props.organizations}
      />
      <UserLayout>
        {loading ? (
          <Loading />
        ) : (
          <Row gutter={16}>
            {organizations?.length > 0 &&
              organizations?.map(
                (organization: Organization, index: number) =>
                  organization.type ===
                    constants.organization.types.COMPANY && (
                    <Col
                      className="single-organization-card-wrapper"
                      key={index}
                      span={10}
                    >
                      <Card
                        title={organization.name}
                        className="single-organization-card"
                        extra={
                          <Space>
                            {stateOrganization?._id !== organization._id ? (
                              <>
                                <Button
                                  onClick={() =>
                                    switchOrganization(organization)
                                  }
                                >
                                  Switch
                                </Button>
                                <Tooltip title="Edit">
                                  <Button
                                    type="ghost"
                                    shape="circle"
                                    onClick={() => showEditModal(organization)}
                                    icon={<EditOutlined />}
                                  />
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <Popconfirm
                                    title={
                                      AppTexts.organization.confirm_delete_title
                                    }
                                    description={
                                      AppTexts.organization
                                        .confirm_delete_description
                                    }
                                    okText={AppTexts.global.yes}
                                    cancelText={AppTexts.global.no}
                                    onConfirm={() =>
                                      deleteOrganization(organization)
                                    }
                                  >
                                    <Button
                                      type="ghost"
                                      shape="circle"
                                      icon={<DeleteOutlined />}
                                    />
                                  </Popconfirm>
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Button disabled>Using</Button>
                                <Tooltip title="Edit">
                                  <Button
                                    type="ghost"
                                    shape="circle"
                                    onClick={() => showEditModal(organization)}
                                    icon={<EditOutlined />}
                                  />
                                </Tooltip>
                              </>
                            )}
                          </Space>
                        }
                      >
                        <p>{organization.address}</p>
                        <p>{organization.website}</p>
                      </Card>
                    </Col>
                  )
              )}

            <Col span={10} className="single-organization-card-wrapper">
              <Card
                title={AppTexts.manage_organization.add_new_title}
                extra={
                  <Button onClick={addOrganization} icon={<PlusOutlined />}>
                    {AppTexts.global.add}
                  </Button>
                }
                className="single-organization-card"
              >
                <p>{AppTexts.manage_organization.add_new_description}</p>
              </Card>
            </Col>
          </Row>
        )}
      </UserLayout>
    </div>
  );
};

export default WithGlobalData(ManageOrganizationPage);
