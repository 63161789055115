import { Form, Input, Button, Card, Layout, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../../services/UserService";
import { App } from "antd";
import ResponseError from "../../../utils/api/ResponseError";
import Content from "../../../components/Content";
import AppTexts from "../../../utils/texts/AppTexts.json";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const onSubmitSucceed = async (data: object) => {
    try {
      await UserService.forgotPassword({ data });
      message.success(AppTexts.auth.forgot_password.email_sent);
      navigate("/auth/login");
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  return (
    <Layout className="layout auth-form-wrapper">
      <Content center>
        <Card className="card-wrapper">
          <Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={onSubmitSucceed}
          >
            <Typography.Title className="text-center" level={3}>
              {AppTexts.auth.forgot_password.title}
            </Typography.Title>

            <Typography.Paragraph>
              {AppTexts.auth.forgot_password.description}
            </Typography.Paragraph>

            <Form.Item
              name="email"
              rules={[
                { required: true, message: AppTexts.global.email_required },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={
                  AppTexts.auth.forgot_password.inputs.email_placeholder
                }
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {AppTexts.auth.forgot_password.submit}
              </Button>
            </Form.Item>

            <Typography.Text>
              <Link to={"/auth/login"}>
                <Typography.Link>
                  {AppTexts.auth.forgot_password.login}
                </Typography.Link>
              </Link>
            </Typography.Text>
          </Form>
        </Card>
      </Content>
    </Layout>
  );
};

export default ForgotPassword;
