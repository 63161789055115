import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/Routes";
import Modal from "./components/Modal";
import LiveMode from "./components/Organization/LiveMode";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <LiveMode />
        <Routes />
        <Modal />
      </BrowserRouter>
    </div>
  );
}

export default App;
