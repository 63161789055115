import { Button, Form, Input, Space, Typography, message } from "antd";
import AppTexts from "../../../utils/texts/AppTexts.json";
import AdminService from "../../../services/AdminService";
import ResponseError from "../../../utils/api/ResponseError";
import { useEffect, useState } from "react";
import { AdminTemplate } from "../../../utils/types";

interface Props {
  edit?: boolean;
  template?: AdminTemplate;
  loadTemplates: Function;
  onSuccessCallback?: Function;
}

export default function AdminTemplateSuggestionForm(props: Props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onSubmitSucceed = async (data: object) => {
    try {
      setLoading(true);
      props?.edit
        ? await AdminService.updateTemplate({
            query: { id: props?.template?._id },
            data,
          })
        : await AdminService.addTemplateSuggestion({ data });
      message.success(AppTexts.admin.templates_page.template_submitted_success);
      form.resetFields();
      props?.loadTemplates();
      props?.onSuccessCallback && props?.onSuccessCallback();
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props?.edit && props?.template) {
      form.setFieldsValue({
        name: props?.template?.name,
        content: props?.template?.content,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.edit, props?.template]);

  return (
    <div>
      <Typography.Title level={3}>
        {AppTexts.admin.suggestion_templates_page.title}
      </Typography.Title>
      <Space className="full-width" direction="vertical" size={20}>
        <Typography.Text>
          {AppTexts.admin.suggestion_templates_page.description}
        </Typography.Text>

        <Form onFinish={onSubmitSucceed} form={form}>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: AppTexts.global.field_required },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="ai_prompt"
            rules={[
              { required: true, message: AppTexts.global.field_required },
            ]}
          >
            <Input.TextArea rows={10} placeholder="AI prompt" />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              disabled={loading}
              type="primary"
              htmlType="submit"
              className="full-width"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
}
