import { GoogleAuthSession, WithGlobalDataProps } from "../../../utils/types";
import { Button, Card, Col, Row, Typography, message, Popconfirm } from "antd";
import HubSpotLogo from "../../../assets/images/gmail.png";
import ResponseError from "../../../utils/api/ResponseError";
import { useEffect, useState } from "react";
import GoogleService from "../../../services/GoogleService";
import { DeleteOutlined } from "@ant-design/icons";
import AppTexts from "../../../utils/texts/AppTexts.json";

function EmailIntegration(props: WithGlobalDataProps) {
  const [accounts, setAccounts] = useState<GoogleAuthSession[]>([]);

  const integrateGmail = async () => {
    try {
      const response = await GoogleService.setupOAuth();
      window.location.replace(response.message);
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  const loadAccounts = async () => {
    try {
      const response = await GoogleService.loadAccounts();
      setAccounts(response.message);
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  const removeAccount = async (entityId: string) => {
    try {
      const data = {
        entityId,
      };
      await GoogleService.removeAccount({
        data,
      });
      await loadAccounts();
      message.success(AppTexts.gmail_accounts.accounts.removed_text);
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  useEffect(() => {
    loadAccounts();
  }, []);

  return (
    <div className="layout email-integration-wrapper">
      <Row gutter={16}>
        {accounts?.length > 0 &&
          accounts?.map((account: GoogleAuthSession, index: number) => (
            <Col className="account-wrapper" key={index} span={10}>
              <Card className="card-wrapper">
                <div>
                  <Typography.Title level={3}>
                    {AppTexts.gmail_accounts.accounts.title}
                  </Typography.Title>
                  <Typography.Paragraph>
                    {AppTexts.gmail_accounts.accounts.description}
                  </Typography.Paragraph>

                  <Typography.Paragraph>
                    <strong>{account.email}</strong>
                  </Typography.Paragraph>
                  <div>
                    <Popconfirm
                      title={
                        AppTexts.gmail_accounts.accounts.confirm_delete_title
                      }
                      description={
                        AppTexts.gmail_accounts.accounts
                          .confirm_delete_description
                      }
                      onConfirm={() => removeAccount(account.entityId)}
                      onCancel={() => {}}
                      okText={AppTexts.global.yes}
                      cancelText={AppTexts.global.no}
                    >
                      <Button icon={<DeleteOutlined />}>
                        {AppTexts.global.remove}
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        <Col span={10} className="account-wrapper">
          <Card className="card-wrapper">
            <div>
              <Typography.Title level={3}>
                {AppTexts.gmail_accounts.integrate.title}
              </Typography.Title>
              <Typography.Paragraph>
                {AppTexts.gmail_accounts.integrate.description}
              </Typography.Paragraph>
              <div className="add-another-account-wrapper">
                <img
                  src={HubSpotLogo}
                  width="109"
                  height="40"
                  alt="Gmail Logo"
                />
                <Button onClick={integrateGmail}>
                  {accounts.length > 0
                    ? AppTexts.gmail_accounts.integrate.add_another
                    : AppTexts.gmail_accounts.integrate.submit}
                </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default EmailIntegration;
