import { CustomContact } from "../../utils/types"

interface Props {
    profile:  CustomContact 
  }
const CostumeContactProfile = (props : Props) =>{
    return (
        <div>
            <pre>{JSON.stringify(props, null, 3)}</pre>
        </div>
    )
}

export default CostumeContactProfile