import { App, Button, Card, Form, Input, Typography } from "antd";
import AppText from "../../../utils/texts/AppTexts.json";
import { useState } from "react";
import UserService from "../../../services/UserService";
import ResponseError from "../../../utils/api/ResponseError";

export default function SecurityForm() {
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();

  const onSubmitSucceed = async (data: object) => {
    setLoading(true);
    try {
      const response = await UserService.updatePassword({ data });
      if (response.message) {
        message.success(AppText.profile_page.security.password_updated);
      }
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="profile-info-form">
      <Typography.Title level={3}>
        {AppText.auth.profile_page.steps.security.title}
      </Typography.Title>
      <Typography.Paragraph>
        {AppText.auth.profile_page.steps.security.content}
      </Typography.Paragraph>
      <Form
        name="update-profile-password"
        layout="vertical"
        onFinish={onSubmitSucceed}
      >
        <Form.Item
          name="old_password"
          label="Current Password"
          rules={[
            {
              required: true,
              message: AppText.global.current_password_required,
            },
          ]}
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item
          name="new_password"
          label="New Password"
          rules={[
            { required: true, message: AppText.global.new_password_required },
          ]}
        >
          <Input type="password" />
        </Form.Item>

        <div>
          <Button loading={loading} type="primary" htmlType="submit">
            {AppText.global.save}
          </Button>
        </div>
      </Form>
    </Card>
  );
}
