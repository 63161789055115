import { Form, Input, Button, Card, Layout, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import UserService from "../../../services/UserService";
import { App } from "antd";
import ResponseError from "../../../utils/api/ResponseError";
import Content from "../../../components/Content";
import AppTexts from "../../../utils/texts/AppTexts.json";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [searchParams] = useSearchParams();

  const onSubmitSucceed = async (data: object) => {
    try {
      data = { ...data, token: searchParams.get("token") };
      await UserService.resetPassword({ data });
      message.success(AppTexts.auth.reset_password.reset_successfully);
      navigate("/auth/login");
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  return (
    <Layout className="layout auth-form-wrapper">
      <Content center>
        <Card className="card-wrapper">
          <Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={onSubmitSucceed}
          >
            <Typography.Title className="text-center" level={3}>
              {AppTexts.auth.reset_password.title}
            </Typography.Title>

            <Typography.Paragraph>
              {AppTexts.auth.reset_password.description}{" "}
            </Typography.Paragraph>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: AppTexts.global.password_required },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {AppTexts.auth.reset_password.submit}
              </Button>
            </Form.Item>

            <Typography.Text>
              <Link to={"/auth/login"}>
                <Typography.Link>
                  {AppTexts.auth.reset_password.login}
                </Typography.Link>
              </Link>
            </Typography.Text>
          </Form>
        </Card>
      </Content>
    </Layout>
  );
};

export default ResetPassword;
