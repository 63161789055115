import { useSelector } from "react-redux";
import Header from "../../../components/Header";
import AdminLayout from "../../../components/Layouts/AdminLayout";
import { RootState, store } from "../../../store/store";
import { useDispatch } from "react-redux";
import { Card, Tabs, message } from "antd";
import { useEffect, useState } from "react";
import {
  AdminTemplate,
  AdminTemplateSuggestion,
  TabParams,
} from "../../../utils/types";
import AdminTemplateForm from "../../../components/Admin/AdminTemplateForm";
import ResponseError from "../../../utils/api/ResponseError";
import AdminService from "../../../services/AdminService";
import AppTexts from "../../../utils/texts/AppTexts.json";
import Content from "../../../components/Content";
import { GlobalSliceReducers } from "../../../store/slices/GlobalSlice";
import AdminTemplateSuggestionForm from "../../../components/Admin/AdminTemplateSuggestionForm";
import AdminListTemplateSuggestions from "../../../components/Admin/AdminListTemplateSuggestions";

export default function AdminTemplateSuggestions() {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state?.auth?.value);

  const [loading, setLoading] = useState(true);
  const [suggestions, setSuggestions] = useState<AdminTemplateSuggestion[]>([]);

  const loadTemplates = async () => {
    try {
      const response = await AdminService.loadTemplatesSuggestions();
      setSuggestions(response?.message);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const deleteTemplate = async (id: string) => {
    try {
      const query = { id };
      await AdminService.deleteTemplateSuggestion({ query });
      await loadTemplates();
      message.success(AppTexts.admin.templates_page.list.deleted_success);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    }
  };

  const showEditModal = (template: AdminTemplate) => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: (
          <AdminTemplateForm
            edit
            onSuccessCallback={() => {
              store.dispatch(GlobalSliceReducers.closeModal());
            }}
            template={template}
            loadTemplates={loadTemplates}
          />
        ),
      })
    );
  };

  const components: TabParams[] = [
    {
      title: "All suggestions",
      component: (
        <AdminListTemplateSuggestions
          loading={loading}
          suggestions={suggestions}
          deleteTemplate={deleteTemplate}
          showEditModal={showEditModal}
        />
      ),
    },
    {
      title: "Add new",
      component: (
        <Content center>
          <Card>
            <AdminTemplateSuggestionForm loadTemplates={loadTemplates} />
          </Card>
        </Content>
      ),
    },
  ];

  useEffect(() => {
    loadTemplates();
  }, []);

  return (
    <div className="layout">
      <Header dispatch={dispatch} auth={auth} />
      <AdminLayout>
        <Tabs
          items={components.map((elem: TabParams, i: number) => {
            return {
              label: elem.title,
              key: String(i + 1),
              children: elem.component,
            };
          })}
        />
      </AdminLayout>
    </div>
  );
}
