export default {
  organization: {
    types: {
      COMPANY: "COMPANY",
      INDIVIDUAL: "INDIVIDUAL",
    },
  },
  user: {
    roles: {
      ADMIN: "ADMIN",
      USER: "USER",
    },
  },
};
