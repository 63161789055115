import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import constants from "../constants";
import { Navigate } from "react-router-dom";

interface Props {
  children: React.ReactElement;
}

export default function UserRole(props: Props) {
  const auth = useSelector((state: RootState) => state?.auth?.value);
  if (auth?.user?.role !== constants.user.roles.USER) {
    if (auth?.user?.role === constants.user.roles.ADMIN) {
      // TODO: This needs to change when we have multiple roles, more than USER and ADMIN
      return <Navigate to="/admin/gpt-prompts" replace />;
    }
  }
  return props?.children;
}
