import { Form, Input, Button, Card, Layout, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../../services/UserService";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthSliceReducers } from "../../../store/slices/AuthSlice";
import { App } from "antd";
import ResponseError from "../../../utils/api/ResponseError";
import Content from "../../../components/Content";
import AppTexts from "../../../utils/texts/AppTexts.json";
import { OrganizationSliceReducers } from "../../../store/slices/OrganizationSlice";
import constants from "../../../utils/constants";

const TwoFactorAuthPage = () => {
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const onSubmitSucceed = async (data: object) => {
    try {
      data = { ...data, token: searchParams.get("token") };
      const response = await UserService.twoFactorAuthSubmit({
        data,
      });
      if (response?.message?.user?.role === constants.user.roles.ADMIN) {
        dispatch(AuthSliceReducers.login(response.message));
        return;
      }
      const organizations = await UserService.listOrganizations({
        headers: {
          access_token: response.message.session.access_token,
        },
      });
      if (organizations?.message?.length === 0) {
        navigate(`/auth/organization-type`, {
          state: { session: response.message },
        });
      } else {
        if (organizations?.message?.length === 1) {
          dispatch(AuthSliceReducers.login(response?.message));
          dispatch(OrganizationSliceReducers.set(organizations.message?.[0]));
        } else {
          navigate(`/auth/select-organization`, {
            state: {
              organizations: organizations?.message,
              session: response.message,
            },
          });
        }
      }
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  return (
    <Layout className="layout auth-form-wrapper">
      <Content center>
        <Card className="card-wrapper">
          <Form
            name="tfa-form"
            initialValues={{ remember: true }}
            onFinish={onSubmitSucceed}
          >
            <Typography.Title className="text-center" level={3}>
              {AppTexts.auth.two_factor_auth.title}
            </Typography.Title>

            <Typography.Paragraph>
              {AppTexts.auth.two_factor_auth.description}
            </Typography.Paragraph>

            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  pattern: new RegExp("^([-]?[1-9][0-9]*|0)$"),
                  message: AppTexts.global.code_required,
                },
              ]}
            >
              <Input prefix={<LockOutlined />} placeholder="Code" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {AppTexts.auth.two_factor_auth.submit}
              </Button>
            </Form.Item>

            <Typography.Text>
              <Link to={"/auth/login"}>
                <Typography.Link>
                  {AppTexts.auth.two_factor_auth.go_back}
                </Typography.Link>
              </Link>
            </Typography.Text>
          </Form>
        </Card>
      </Content>
    </Layout>
  );
};

export default TwoFactorAuthPage;
