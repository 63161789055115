import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Header from "../../../components/Header";
import ProfileLayout from "../../../components/Layouts/ProfileLayout";
import AdminLayout from "../../../components/Layouts/AdminLayout";

interface AdminProfilePageProps {}

function AdminProfilePage(props: AdminProfilePageProps) {
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();

  return (
    <div className="layout">
      <Header dispatch={dispatch} auth={auth} />
      <AdminLayout>
        <ProfileLayout dispatch={dispatch} accounts={[]} settings={undefined} />
      </AdminLayout>
    </div>
  );
}

export default AdminProfilePage;
