import { Button, Form, Input, Typography, message } from "antd";
import AppTexts from "../../../utils/texts/AppTexts.json";
import { UserSubject } from "../../../utils/types";
import ResponseError from "../../../utils/api/ResponseError";
import OrganizationService from "../../../services/OrganizationService";

interface Props {
  edit?: boolean;
  userSubject?: UserSubject;
  onSuccessCallback?: Function;
}

export default function SubjectForm(props: Props) {
  const onSubmitSucceed = async (data: UserSubject) => {
    try {
      const query = { id: props?.userSubject?._id };
      const response = props?.edit
        ? await OrganizationService.updateSubject({ data, query })
        : await OrganizationService.addSubject({ data });

      if (props?.onSuccessCallback) props.onSuccessCallback(response);
      message.success(AppTexts.organization_subjects.saved_successfully);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    }
  };

  return (
    <div>
      <Typography.Title level={2}>
        {AppTexts.organization_subjects.title}
      </Typography.Title>
      <Form
        initialValues={{
          subject: props?.userSubject?.subject,
        }}
        onFinish={onSubmitSucceed}
      >
        <Form.Item
          name="subject"
          rules={[{ required: true, message: AppTexts.global.field_required }]}
        >
          <Input placeholder="Subject" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="full-width">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
