import { Popconfirm, Row, message } from "antd";
import { AdminPromptCategoriesI, AdminSubject } from "../../../utils/types";
import ListCard from "../../ListCard";
import { useNavigate } from "react-router-dom";
import AdminService from "../../../services/AdminService";
import ResponseError from "../../../utils/api/ResponseError";

import AppTexts from "../../../utils/texts/AppTexts.json";
interface Props {
  subjects: AdminSubject[];
  loading: boolean;
  loadSubjects: Function;
  promptCategories: AdminPromptCategoriesI[];
  loadPromptCategories: Function;
}

export default function AdminListSubjects(props: Props) {
  const navigate = useNavigate();
  const deleteSubject = async (subject: AdminSubject) => {
    try {
      await AdminService.deleteSubject({
        query: { id: subject?._id },
      });
      props?.loadSubjects();
      props?.loadPromptCategories();
      message.success(AppTexts.organization_subjects.saved_successfully);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    }
  };

  const editSubject = (subject: AdminSubject) => {
    navigate(`/admin/update-subject?id=${subject?._id}`);
  };

  return (
    <div>
      {props.subjects.length > 0 && !props.loading && (
        <Row gutter={[16, 16]}>
          {props.subjects.map((subject: AdminSubject, index: number) => {
            return (
              <ListCard
                key={index}
                title={subject.subject}
                description={
                  props.promptCategories.find(
                    (elem) => elem._id === subject.prompt_category
                  )?.category as string
                }
                actions={[
                  <span
                    onClick={() => {
                      editSubject(subject);
                    }}
                  >
                    {AppTexts.global.edit}
                  </span>,
                  <Popconfirm
                    title={AppTexts.organization_subjects.delete_title}
                    description={
                      AppTexts.organization_subjects.delete_description
                    }
                    onConfirm={() => deleteSubject(subject)}
                    onCancel={() => {}}
                    okText={AppTexts.global.yes}
                    cancelText={AppTexts.global.no}
                  >
                    <span>{AppTexts.global.delete}</span>
                  </Popconfirm>,
                ]}
              />
            );
          })}
        </Row>
      )}
    </div>
  );
}
