import { Button, Form, Input, Modal, Space, message } from "antd";
import { useEffect, useState } from "react";
import ResponseError from "../../../utils/api/ResponseError";
import OrganizationService from "../../../services/OrganizationService";
import AppTexts from "../../../utils/texts/AppTexts.json";
import { CustomContact, HubSpotContact } from "../../../utils/types";
import Loading from "../../Loading";

import ReactQuill from "react-quill";

interface Props {
  context: string;
  type: string;
  selectedTemplates: string[];
  organization_id: string;
  content: string;
  templateSaved: boolean;
  setTemplateSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  contact: HubSpotContact | CustomContact;
}

export default function Confirm(props: Props) {
  const { content, setContent, contact } = props;
  const combinedContact = {
    name: (contact as HubSpotContact)?.properties?.firstname
      ? (contact as HubSpotContact)?.properties?.firstname
      : (contact as CustomContact)?.name,
    email: (contact as HubSpotContact)?.properties?.email
      ? (contact as HubSpotContact)?.properties?.email
      : (contact as CustomContact)?.email,
  };
  const [loading, setLoading] = useState(!content ? true : false);
  const [saveTemplateLoading, setSaveTemplateLoading] = useState(false);
  const [nameModal, setNameModal] = useState(false);
  const [templateName, setTemplateName] = useState("");

  const generateEmailTemplate = async () => {
    try {
      const data = {
        context: props.context,
        type: props.type,
        selectedTemplates: props.selectedTemplates,
        contactName: combinedContact?.name,
      };
      const response = await OrganizationService.generateEmailTemplate({
        data,
      });
      setContent(response.message.openAIContent);
      setLoading(false);
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  const copyContent = () => {
    navigator.clipboard.writeText(content).then(
      function () {
        message.success("Email template copied successfully");
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const reGenerate = () => {
    setContent("");
    setLoading(true);
    generateEmailTemplate();
    props?.setTemplateSaved(false);
  };

  const saveAsTemplate = async () => {
    try {
      setSaveTemplateLoading(true);
      const data = {
        name: templateName,
        content: content,
      };
      await OrganizationService.createEmailTemplate({ data });
      props?.setTemplateSaved(true);
      message.success(AppTexts.send_email.steps.confirm.template_saved_message);
    } catch (error: unknown) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setSaveTemplateLoading(false);
      setNameModal(false);
    }
  };

  useEffect(() => {
    if (!content) {
      generateEmailTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Modal
        title="Template name"
        width={500}
        open={nameModal}
        onOk={() => {}}
        onCancel={() => setNameModal(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setNameModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button key="save" onClick={saveAsTemplate}>
            Save
          </Button>,
        ]}
      >
        <Form.Item>
          <Input
            placeholder="Generated Template Name"
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setTemplateName(e.currentTarget.value);
            }}
          />
        </Form.Item>
      </Modal>
      {loading && <Loading />}
      {content && !loading && (
        <div>
          <p>
            The AI has successfully generated an email template. We kindly
            request your review of the template and, as needed, the substitution
            of relevant information.
          </p>
          <Form.Item>
            <ReactQuill
              theme="snow"
              preserveWhitespace={true}
              modules={{
                clipboard: {
                 // TODO: toggle to add extra line breaks when pasting HTML:
                  matchVisual: false,
                },
              }}
              value={content}
              onChange={(text: string) => setContent(text)}
            />
          </Form.Item>
          <Space size={20}>
            <Button onClick={copyContent}>Copy</Button>
            <Button onClick={reGenerate}>Regenerate</Button>
            <Button
              loading={saveTemplateLoading}
              disabled={saveTemplateLoading || props?.templateSaved}
              onClick={() => setNameModal(true)}
            >
              {props?.templateSaved
                ? AppTexts.send_email.steps.confirm.template_saved_button_title
                : AppTexts.send_email.steps.confirm.save_as_template}
            </Button>
          </Space>
        </div>
      )}
    </div>
  );
}
