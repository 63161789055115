import { Button, Space, Typography, message } from "antd";
import AppTexts from "../../../utils/texts/AppTexts.json";
import OrganizationService from "../../../services/OrganizationService";
import ResponseError from "../../../utils/api/ResponseError";
import { useState } from "react";
import { OrganizationSliceReducers } from "../../../store/slices/OrganizationSlice";
import { store } from "../../../store/store";
import { GlobalSliceReducers } from "../../../store/slices/GlobalSlice";

export default function SwitchToLiveModeContent() {
  const [loading, setLoading] = useState(false);
  const updateLiveMode = async () => {
    try {
      setLoading(true);
      await OrganizationService.updateLiveMode({
        data: { live_mode: true },
      });
      message.success(AppTexts.live_mode.updated_success);
      store?.dispatch(
        OrganizationSliceReducers.updateLiveMode({ live_mode: true })
      );
      store.dispatch(GlobalSliceReducers.closeModal());
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography.Title level={3}>{AppTexts.live_mode.title}</Typography.Title>
      <Space size={15} direction="vertical">
        <Typography.Text>{AppTexts.live_mode.description}</Typography.Text>

        <Button
          loading={loading}
          disabled={loading}
          className="full-width"
          type="primary"
          onClick={updateLiveMode}
        >
          {AppTexts.live_mode.submit_button}
        </Button>
      </Space>
    </div>
  );
}
