import { Card, Table } from "antd";
import AddCustomContact from "./AddCustomContact";
import { CustomContact, HubSpotContact } from "../../utils/types";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

interface Props {
  customContacts: CustomContact[];
  columns: ColumnsType<HubSpotContact | CustomContact>;
  loadCustomContacts: Function;
}

export default function CustomContacts(props: Props) {
  const navigate = useNavigate();
  const handleRowClick = (record: any) => {
    console.log("here",record)
   
    navigate(`/organization/contact/profile?id=${record._id}&hubspot=${false}`);
  };
  
  return (
    <div>
      {props?.customContacts?.length > 0 ? (
        <div>
          <Table
            dataSource={props?.customContacts}
            columns={props?.columns}
            pagination={false}
          />
        </div>
      ) : (
        <Card className="custom-contacts-card">
          <AddCustomContact
            onSuccessCallback={() => props?.loadCustomContacts()}
          />
        </Card>
      )}
    </div>
  );
}
