import { ErrorResponse, JoiValidationError } from "../types";

class ResponseError {
  private errNotFoundMessage = "Something went wrong.";
  public isValidationError(err: unknown) {
    return (err as ErrorResponse).name === "joi-error-validation";
  }
  public showMessage(err: unknown) {
    if (this.isValidationError(err)) {
      return (
        (err as JoiValidationError)?.details?.validation_errors?.[0]?.message ||
        this.errNotFoundMessage
      );
    }
    return (err as ErrorResponse).message || this.errNotFoundMessage;
  }
}

export default new ResponseError();
