import { Form, Input, Select } from "antd";
import AppTexts from "../../../utils/texts/AppTexts.json";
import { AdminPromptCategoriesI } from "../../../utils/types";

interface props {
  context: string;
  setContext: React.Dispatch<React.SetStateAction<string>>;
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  promptCategories: AdminPromptCategoriesI[];
}

export default function ContextInformation(props: props) {
  return (
    <div>
      <Form.Item name="context">
        <Input.TextArea
          rows={4}
          defaultValue={props?.context}
          value={props?.context}
          onChange={(e) => props?.setContext(e?.target?.value)}
          placeholder="Give the AI context & more information about this email template."
        />
      </Form.Item>
      <Form.Item
        name="type"
        rules={[{ required: true, message: AppTexts.global.field_required }]}
        initialValue={null}
      >
        <Select
          placeholder="Select the type of email you want to send"
          value={props.type}
          onChange={(e) => {
            props.setType(e);
          }}
        >
          {props?.promptCategories.map((category, index: number) => {
            return (
              <Select.Option
                key={`${index}`}
                value={category.prompt}
                onChange={(e: any) => props.setType(e.target.value)}
              >
                {category.category}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );
}
