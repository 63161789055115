import { Endpoint } from "../utils/api/Endpoints";
import {
  AdminPromptCategoriesI,
  AdminSettings,
  AdminSettingsAddGPTPromptResponse,
  AdminSubject,
  AdminTemplate,
  AdminTemplateSuggestion,
  ApiFetchRequestOptions,
  BaseApiResponse,
} from "../utils/types";
import ApiProvider from "./AbstractApiProvider";

class AdminService extends ApiProvider {
  public async addGPTPrompt(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminSettingsAddGPTPromptResponse>> {
    return this.callApi(Endpoint.admin.settings.addGPTPrompt, options);
  }
  public async listGPTPrompt(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminSettings[]>> {
    return this.callApi(Endpoint.admin.settings.listGPTPrompt, options);
  }
  public async deleteGPTPrompt(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<boolean>> {
    return this.callApi(Endpoint.admin.settings.deleteGPTPrompt, options);
  }
  public async singleGPTPrompt(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminSettings>> {
    return this.callApi(Endpoint.admin.settings.singleGPTPrompt, options);
  }
  public async updateGPTPrompt(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminSettings>> {
    return this.callApi(Endpoint.admin.settings.updateGPTPrompt, options);
  }
  public async listPromptCategories(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminPromptCategoriesI[]>> {
    return this.callApi(Endpoint.admin.settings.listPromptCategories, options);
  }
  public async deletePromptCategory(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<boolean>> {
    return this.callApi(Endpoint.admin.settings.deletePromptCategory, options);
  }
  public async singlePromptCategory(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminPromptCategoriesI>> {
    return this.callApi(Endpoint.admin.settings.listPromptCategories, options);
  }
  public async addPromptCategory(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminPromptCategoriesI>> {
    return this.callApi(Endpoint.admin.settings.addPromptCategory, options);
  }
  public async editPromptCategory(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminPromptCategoriesI>> {
    return this.callApi(Endpoint.admin.settings.editPromptCategory, options);
  }
  public async quickGPT(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<string>> {
    return this.callApi(Endpoint.admin.settings.quickGPT, options);
  }
  public async addTemplate(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminTemplate>> {
    return this.callApi(Endpoint.admin.templates.add, options);
  }

  public async loadTemplates(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminTemplate[]>> {
    return this.callApi(Endpoint.admin.templates.list, options);
  }

  public async deleteTemplate(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<boolean>> {
    return this.callApi(Endpoint.admin.templates.delete, options);
  }

  public async updateTemplate(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminTemplate>> {
    return this.callApi(Endpoint.admin.templates.update, options);
  }

  public async addTemplateSuggestion(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminTemplateSuggestion>> {
    return this.callApi(Endpoint.admin.suggestions.add, options);
  }

  public async loadTemplatesSuggestions(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminTemplateSuggestion[]>> {
    return this.callApi(Endpoint.admin.suggestions.list, options);
  }

  public async deleteTemplateSuggestion(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<boolean>> {
    return this.callApi(Endpoint.admin.suggestions.delete, options);
  }
  public async listSubjects(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminSubject[]>> {
    return this.callApi(Endpoint.admin.settings.listSubjects, options);
  }
  public async deleteSubject(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<boolean>> {
    return this.callApi(Endpoint.admin.settings.deleteSubject, options);
  }
  public async addSubject(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminSubject>> {
    return this.callApi(Endpoint.admin.settings.addSubject, options);
  }
  public async updateSubject(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminSubject>> {
    return this.callApi(Endpoint.admin.settings.updateSubject, options);
  }
  public async singleSubject(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<AdminSubject>> {
    return this.callApi(Endpoint.admin.settings.singleSubject, options);
  }
}

export default new AdminService();
