import { Button, Card, Space, Typography, message } from "antd";
import { useState } from "react";
import AppTexts from "../../../utils/texts/AppTexts.json";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../store/store";
import OrganizationService from "../../../services/OrganizationService";
import { OrganizationSliceReducers } from "../../../store/slices/OrganizationSlice";
import ResponseError from "../../../utils/api/ResponseError";

export default function LiveMode() {
  const organization = useSelector(
    (state: RootState) => state?.organization?.value
  );
  const [loading, setLoading] = useState(false);

  const updateMode = async () => {
    try {
      setLoading(true);
      await OrganizationService.updateLiveMode({
        data: { live_mode: !organization?.live_mode },
      });

      message.success(
        !organization?.live_mode
          ? AppTexts.live_mode.updated_success
          : AppTexts.live_mode.updated_test_mode_success
      );
      store?.dispatch(
        OrganizationSliceReducers.updateLiveMode({
          live_mode: !organization?.live_mode,
        })
      );
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };
  return (
    <Card>
      <div>
        <Typography.Title level={3}>
          {organization?.live_mode
            ? AppTexts.live_mode.test_mode_title
            : AppTexts.live_mode.title}
        </Typography.Title>
        <Space size={15} direction="vertical">
          <Typography.Text>
            {organization?.live_mode
              ? AppTexts.live_mode.test_mode_description
              : AppTexts.live_mode.description}
          </Typography.Text>

          <Button
            loading={loading}
            disabled={loading}
            className="full-width"
            type="primary"
            onClick={updateMode}
          >
            {organization?.live_mode
              ? AppTexts.live_mode.test_mode_submit_button
              : AppTexts.live_mode.submit_button}
          </Button>
        </Space>
      </div>
    </Card>
  );
}
