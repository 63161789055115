import { Button, Modal, Steps, message } from "antd";
import { useEffect, useState } from "react";
import ContextInformation from "./ContextInformation";
import SelectTemplates from "./SelectTemplates";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import OrganizationService from "../../../services/OrganizationService";
import {
  AdminPromptCategoriesI,
  CustomContact,
  EmailTemplate,
  GoogleAuthSession,
  HubSpotContact,
} from "../../../utils/types";
import ResponseError from "../../../utils/api/ResponseError";
import EmailSelect from "./EmailSelect";
import GoogleService from "../../../services/GoogleService";
import Confirm from "./Confirm";
import Send from "./Send";
import AdminService from "../../../services/AdminService";

const STEPS = {
  Context: 0,
  Template: 1,
  Email: 2,
  Confirm: 3,
  Send: 4,
};

interface Props {
  contact: HubSpotContact | CustomContact;
  onClose: Function;
}

export default function SendEmailForm(props: Props) {
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [accounts, setAccounts] = useState<GoogleAuthSession[]>([]);

  const [context, setContext] = useState("");
  const [type, setType] = useState("");
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [selectedAccount, setSelectedAccount] =
    useState<GoogleAuthSession | null>(null);
  const [content, setContent] = useState("");
  const [includeSignature, setIncludeSignature] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [promptCategories, setPromptCategories] = useState<
    AdminPromptCategoriesI[]
  >([]);

  const [templateSaved, setTemplateSaved] = useState(false);

  const organization = useSelector(
    (state: RootState) => state.organization.value
  );

  const loadPromptCategories = async () => {
    try {
      const response = await AdminService.listPromptCategories();
      setPromptCategories(response.message);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    }
  };

  const loadAccounts = async () => {
    try {
      const response = await GoogleService.loadAccounts({});
      setAccounts(response.message);
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  const loadTemplates = async () => {
    try {
      const response = await OrganizationService.listEmailTemplates({});
      setTemplates(response?.message);
    } catch (err) {
      message.error(ResponseError.showMessage(err));
    }
  };

  const submit = () => {
    if (currentStep === STEPS.Context) {
      if (!context) {
        message.error("Context field is required");
        return;
      } else if (!type) {
        message.error("Type of email is required");
        return;
      }
    }

    if (currentStep === STEPS.Template) {
      if (selectedTemplates?.length === 0) {
        message.error("You must select at least one template.");
        return;
      }
      if (selectedTemplates?.length > 5) {
        message.error("You must select a maximum of five templates.");
        return;
      }
    }

    if (currentStep === STEPS.Email) {
      if (!selectedAccount) {
        message.error("You must select one email account");
        return;
      }
    }

    if (currentStep !== STEPS.Send) {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    loadTemplates();
    loadAccounts();
    loadPromptCategories();
  }, []);

  return (
    <div>
      <Modal
        title="Send email"
        width={700}
        open={true}
        onOk={submit}
        onCancel={() => props?.onClose()}
        footer={
          currentStep === STEPS.Send
            ? []
            : [
                <Button
                  key="back"
                  onClick={() =>
                    currentStep !== STEPS.Context &&
                    setCurrentStep(currentStep - 1)
                  }
                >
                  Back
                </Button>,
                <Button key="submit" type="primary" onClick={submit}>
                  {currentStep === STEPS.Send ? "Send" : "Next"}
                </Button>,
              ]
        }
      >
        <div className="steps-wrapper">
          <Steps
            current={currentStep}
            items={[
              {
                title: "Context",
                description: "",
              },
              {
                title: "Templates",
                description: "",
              },
              {
                title: "Email",
                description: "",
              },
              {
                title: "Confirm",
                description: "",
              },
              {
                title: "Send",
                description: "",
              },
            ]}
          />
        </div>

        <div className="steps-content-wrapper">
          {currentStep === STEPS.Context && (
            <ContextInformation
              promptCategories={promptCategories}
              context={context}
              setContext={setContext}
              type={type}
              setType={setType}
            />
          )}
          {currentStep === STEPS.Template && templates?.length > 0 && (
            <SelectTemplates
              templates={templates}
              selectedTemplates={selectedTemplates}
              setSelectedTemplates={setSelectedTemplates}
            />
          )}
          {currentStep === STEPS.Email && accounts?.length > 0 && (
            <EmailSelect
              accounts={accounts}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
            />
          )}
          {currentStep === STEPS.Confirm && (
            <Confirm
              contact={props?.contact}
              context={context}
              type={type}
              selectedTemplates={selectedTemplates}
              organization_id={organization._id}
              content={content}
              setContent={setContent}
              templateSaved={templateSaved}
              setTemplateSaved={setTemplateSaved}
            />
          )}
          {currentStep === STEPS.Send && (
            <Send
              contact={props?.contact}
              setContent={setContent}
              content={content}
              onClose={props.onClose}
              selectedAccount={selectedAccount}
              includeSignature={includeSignature}
              setIncludeSignature={setIncludeSignature}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}
