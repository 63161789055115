import { Form, Input, Space, Typography } from "antd";
import { SuggestedTemplate } from "../../utils/types";
import AppTexts from "../../utils/texts/AppTexts.json";

interface Props {
  template: SuggestedTemplate;
}

export default function ViewTemplate(props: Props) {
  const { view_modal } = AppTexts.organization_templates.templates_suggestions;
  return (
    <div>
      <Space direction="vertical" className="view-suggested-template-wrapper">
        <Typography.Title level={4}>{view_modal.title}</Typography.Title>
        <div>
          <Form.Item name="name">
            <Input defaultValue={props?.template?.name} placeholder="Name" />
          </Form.Item>
          <Form.Item name="content">
            <Input.TextArea
              defaultValue={props?.template?.content}
              rows={20}
              placeholder="Content"
            />
          </Form.Item>
        </div>
      </Space>
    </div>
  );
}
