import { Select } from "antd";
import { GoogleAuthSession } from "../../../utils/types";

interface Props {
  accounts: GoogleAuthSession[];
  selectedAccount: GoogleAuthSession | null;
  setSelectedAccount: React.Dispatch<
    React.SetStateAction<GoogleAuthSession | null>
  >;
}

export default function EmailSelect(props: Props) {
  if (props.accounts.length === 1) {
    props.setSelectedAccount(props.accounts[0]);
  }

  const handleChange = (value: string) => {
    const account = props?.accounts?.find(
      (account: GoogleAuthSession) => account?.entityId === value
    );
    if (account) {
      props.setSelectedAccount(account);
    }
  };

  return (
    <div>
      <p>
        Please choose the desired email account from which you would like to
        send the AI-generated email template.
      </p>
      <Select
        placeholder="Select email"
        className="full-width"
        onChange={handleChange}
        value={props?.selectedAccount?.email}
        options={props?.accounts?.map((account: GoogleAuthSession) => ({
          value: account.entityId?.toString(),
          label: account?.email,
        }))}
      />
    </div>
  );
}
