import { useSelector } from "react-redux";
import Header from "../../../components/Header";
import AdminLayout from "../../../components/Layouts/AdminLayout";
import { RootState } from "../../../store/store";
import { useDispatch } from "react-redux";
import { Breadcrumb, Space } from "antd";
import AdminAddGPTPromptForm from "../../../components/Admin/AdminAddGPTPromptForm";
export default function AdminGPTUpdatePromptPage() {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state?.auth?.value);
  return (
    <div className="layout">
      <Header dispatch={dispatch} auth={auth} />
      <AdminLayout>
        <div>
          <Space direction="vertical" size={20}>
            <Breadcrumb
              items={[
                {
                  title: <a href="/admin/gpt-prompts">All prompts</a>,
                },
                {
                  title: "Update prompt",
                },
              ]}
            />
            <AdminAddGPTPromptForm />
          </Space>
        </div>
      </AdminLayout>
    </div>
  );
}
