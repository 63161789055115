import { Card, Col, Popconfirm, Row, Typography } from "antd";
import { AdminTemplateSuggestion } from "../../../utils/types";
import Loading from "../../Loading";
import Meta from "antd/es/card/Meta";
import Content from "../../Content";
import AppTexts from "../../../utils/texts/AppTexts.json";

interface Props {
  loading: boolean;
  suggestions: AdminTemplateSuggestion[];
  deleteTemplate: Function;
  showEditModal: Function;
}

export default function AdminListTemplateSuggestions(props: Props) {
  return (
    <div className="admin-templates-page">
      {props?.loading && <Loading />}
      {!props?.loading && props?.suggestions?.length === 0 && (
        <Content center>
          <Card>
            <Typography.Title level={3}>
              {AppTexts.admin.templates_page.list.title}
            </Typography.Title>
            <Typography.Text>
              {AppTexts.admin.templates_page.list.description}
            </Typography.Text>
          </Card>
        </Content>
      )}
      {!props?.loading && props?.suggestions?.length > 0 && (
        <Row gutter={[16, 16]}>
          {props?.suggestions?.map((suggestion) => (
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              key={suggestion?._id}
              className="template-col-wrapper"
            >
              <Card
                hoverable
                actions={[
                  <Popconfirm
                    title="Delete the suggestion?"
                    description="Are you sure to delete this suggestion?"
                    onConfirm={() => props?.deleteTemplate(suggestion._id)}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <span>Delete</span>
                  </Popconfirm>,
                ]}
              >
                <Meta
                  title={suggestion?.name}
                  description={suggestion?.ai_prompt?.substring(0, 100) + "..."}
                />
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}
