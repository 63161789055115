import { Button, Form, Input, Typography, message } from "antd";
import {
  UserOutlined,
  PhoneOutlined,
  DiffOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import AppTexts from "../../../utils/texts/AppTexts.json";
import { Organization } from "../../../utils/types";
import ResponseError from "../../../utils/api/ResponseError";
import OrganizationService from "../../../services/OrganizationService";
import { useEffect } from "react";
import constants from "../../../utils/constants";

interface IOrganizationFormProps {
  onSubmit: Function;
  back: Function;
  organization?: Organization;
}

export default function OrganizationForm(props: IOrganizationFormProps) {
  const [form] = Form.useForm();

  const onSubmitSucceed = async (data: object) => {
    try {
      const query = { id: props?.organization?._id };
      const editData = { ...data, type: constants.organization.types.COMPANY };
      if (props?.organization?._id) {
        await OrganizationService.updateOrganization({
          data: editData,
          query,
        });
      } else {
        if (props?.onSubmit) {
          props.onSubmit(editData);
        }
      }
      message.success(
        `Organization ${
          props.organization?._id ? "updated" : "created"
        } successfully.`
      );
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  const onSubmitFailed = () => {};

  useEffect(() => {
    if (props?.organization?._id) {
      form.setFieldsValue({
        name: props?.organization?.name,
        phone_number: props?.organization?.phone_number,
        address: props?.organization?.address,
        website: props?.organization?.website,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.organization]);

  return (
    <Form
      form={form}
      name="login-form"
      initialValues={{ remember: true }}
      onFinish={onSubmitSucceed}
      onFinishFailed={onSubmitFailed}
    >
      <Typography.Title className="text-center" level={3}>
        Add your company
      </Typography.Title>

      <Typography.Paragraph>
        After entering the required information, you will be able to use our
        services.
      </Typography.Paragraph>

      <Form.Item
        name="name"
        rules={[{ required: true, message: AppTexts.global.name_required }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Name" />
      </Form.Item>

      <Form.Item
        name="phone_number"
        rules={[{ required: true, message: AppTexts.global.phone_required }]}
      >
        <Input prefix={<PhoneOutlined />} placeholder="Phone number" />
      </Form.Item>

      <Form.Item
        name="address"
        rules={[{ required: true, message: AppTexts.global.address_required }]}
      >
        <Input prefix={<DiffOutlined />} placeholder="Address" />
      </Form.Item>

      <Form.Item
        name="website"
        rules={[{ required: true, message: AppTexts.global.website_required }]}
      >
        <Input prefix={<WechatOutlined />} placeholder="Website" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="full-width">
          Submit
        </Button>
      </Form.Item>

      {/* <Typography.Text onClick={() => props.back()}>Go back</Typography.Text> */}
    </Form>
  );
}
