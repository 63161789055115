import { useSelector } from "react-redux";
import Header from "../../../components/Header";
import AdminLayout from "../../../components/Layouts/AdminLayout";
import { RootState } from "../../../store/store";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { useEffect, useState } from "react";
import { Tabs } from "antd";
import {
  AdminPromptCategoriesI,
  AdminSettings,
  TabParams,
} from "../../../utils/types";
import AdminAddGPTPromptForm from "../../../components/Admin/AdminAddGPTPromptForm";
import AdminListGPTPrompts from "../../../components/Admin/AdminListGPTPrompts";
import ResponseError from "../../../utils/api/ResponseError";
import AdminService from "../../../services/AdminService";
import AdminPromptCategories from "../../../components/Admin/AdminPromptCategories";

export default function AdminGPTPromptsPage() {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state?.auth?.value);

  const [loading, setLoading] = useState(true);
  const [prompts, setPrompts] = useState<AdminSettings[]>([]);
  const [promptCategories, setPromptCategories] = useState<
    AdminPromptCategoriesI[]
  >([]);

  const loadGPTPrompts = async () => {
    try {
      const response = await AdminService.listGPTPrompt();
      setPrompts(response.message);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const loadPromptCategories = async () => {
    try {
      const response = await AdminService.listPromptCategories();
      setPromptCategories(response.message);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    loadGPTPrompts();
    loadPromptCategories();
  }, []);

  const components: TabParams[] = [
    {
      title: "All prompts",
      component: (
        <AdminListGPTPrompts
          loadGPTPrompts={loadGPTPrompts}
          loading={loading}
          prompts={prompts}
          loadPromptCategories={loadPromptCategories}
        />
      ),
    },
    {
      title: "Add new prompt",
      component: <AdminAddGPTPromptForm loadGPTPrompts={loadGPTPrompts} />,
    },
    {
      title: "Prompt categories",
      component: <AdminPromptCategories prompts={prompts} loading={loading} promptCategories={promptCategories} loadPromptCategories={loadPromptCategories}/>,
    },
  ];

  return (
    <div className="layout admin">
      <Header dispatch={dispatch} auth={auth} />
      <AdminLayout>
        <Tabs
          items={components.map((elem: TabParams, i: number) => {
            return {
              label: elem.title,
              key: String(i + 1),
              children: elem.component,
            };
          })}
        />
      </AdminLayout>
    </div>
  );
}
