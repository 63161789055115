import { store } from "../../../store/store";
import { useEffect, useState } from "react";
import Loading from "../../Loading";
import { Button, Popconfirm, Row, message } from "antd";
import ResponseError from "../../../utils/api/ResponseError";
import OrganizationService from "../../../services/OrganizationService";
import { UserSubject } from "../../../utils/types";
import ListCard from "../../ListCard";
import { GlobalSliceReducers } from "../../../store/slices/GlobalSlice";
import SubjectForm from "./SubjectForm";

import AppTexts from "../../../utils/texts/AppTexts.json";

export default function Subjects() {
  const [loading, setLoading] = useState(true);
  const [userSubjects, setUserSubjects] = useState<UserSubject[]>([]);

  const loadSubjects = async () => {
    try {
      const response = await OrganizationService.listSubjects();
      setUserSubjects(response.message);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const showAddModal = async () => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: (
          <SubjectForm
            onSuccessCallback={() => {
              store.dispatch(GlobalSliceReducers.closeModal());
              loadSubjects();
            }}
          />
        ),
      })
    );
  };

  const showEditModal = async (userSubject: UserSubject) => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: (
          <SubjectForm
            edit
            userSubject={userSubject}
            onSuccessCallback={() => {
              store.dispatch(GlobalSliceReducers.closeModal());
              loadSubjects();
            }}
          />
        ),
      })
    );
  };

  const deleteSubject = async (userSubject: UserSubject) => {
    try {
      await OrganizationService.deleteSubject({
        query: { id: userSubject?._id },
      });
      setLoading(true);
      loadSubjects();
      message.success(AppTexts.organization_subjects.deleted_successfully);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSubjects();
  }, []);

  return (
    <div>
      {loading && <Loading />}

      <Button onClick={showAddModal}>Add subject</Button>
      <hr />
      <Row gutter={[16, 16]}>
        {userSubjects.map((elem, index: number) => {
          return (
            <ListCard
              key={index}
              title={elem.subject}
              description=""
              actions={[
                <span
                  onClick={() => {
                    showEditModal(elem);
                  }}
                >
                  {AppTexts.global.edit}
                </span>,
                <Popconfirm
                  title={AppTexts.organization_subjects.delete_title}
                  description={
                    AppTexts.organization_subjects.delete_description
                  }
                  onConfirm={() => deleteSubject(elem)}
                  onCancel={() => {}}
                  okText={AppTexts.global.yes}
                  cancelText={AppTexts.global.no}
                >
                  <span>{AppTexts.global.delete}</span>
                </Popconfirm>,
              ]}
            />
          );
        })}
      </Row>
    </div>
  );
}
