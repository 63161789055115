import { Layout, Tabs } from "antd";
import {
  GoogleAuthSession,
  Organization,
  TabParams,
  UserSettings,
} from "../../utils/types";
import ProfileInfoForm from "../Forms/ProfilePageForm/ProfileInfoForm";
import SecurityForm from "../Forms/ProfilePageForm/SecurityForm";
import SettingsForm from "../Forms/ProfilePageForm/SettingsForm";
import AppTexts from "../../utils/texts/AppTexts.json";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import LiveMode from "../Forms/ProfilePageForm/LiveMode";
import Subjects from "../Forms/ProfilePageForm/Subjects";

interface IUserLayoutProps {
  accounts: GoogleAuthSession[];
  settings: UserSettings | undefined;
  organization?: Organization;
  dispatch: Dispatch<AnyAction>;
}

export default function ProfileLayout(props: IUserLayoutProps) {
  const components: TabParams[] = [
    {
      title: AppTexts.auth.profile_page.steps.personal_details.title,
      component: <ProfileInfoForm />,
    },
    {
      title: AppTexts.auth.profile_page.steps.security.title,
      component: <SecurityForm />,
    },
    {
      title: AppTexts.auth.profile_page.steps.settings.title_profile,
      component: (
        <SettingsForm
          dispatch={props.dispatch}
          organization={props.organization}
          accounts={props.accounts}
          settings={props.settings}
        />
      ),
    },
    {
      title: AppTexts.auth.profile_page.steps.settings.live_mode,
      component: <LiveMode />,
    },
    {
      title: AppTexts.auth.profile_page.steps.subject.title,
      component: <Subjects />,
    },
  ];

  return (
    <Layout>
      <Tabs
        items={components.map((elem: TabParams, i: number) => {
          return {
            label: elem.title,
            key: String(i + 1),
            children: elem.component,
          };
        })}
      />
    </Layout>
  );
}
