import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
  message,
} from "antd";

import AppTexts from "../../../utils/texts/AppTexts.json";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AdminPromptCategoriesI, AdminSubject } from "../../../utils/types";
import ResponseError from "../../../utils/api/ResponseError";
import AdminService from "../../../services/AdminService";

interface Props {
  loadSubjects?: Function;
}

export default function AdminSubjectsForm(props: Props) {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [promptCategories, setPromptCategories] = useState<
    AdminPromptCategoriesI[]
  >([]);
  const [form] = Form.useForm();

  const update_id = searchParams.get("id");

  const loadPromptCategories = async () => {
    try {
      const response = await AdminService.listPromptCategories();
      setPromptCategories(response.message);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    }
  };

  const onSubmitSucceed = async (data: AdminSubject) => {
    try {
      setLoading(true);
      update_id
        ? await AdminService.updateSubject({ query: { id: update_id }, data })
        : await AdminService.addSubject({ data });
      message.success(AppTexts.admin.subjects_page.saved_successfully);
      !update_id && form.resetFields();
      props?.loadSubjects && props?.loadSubjects();
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const singleSubject = async (id: string) => {
    try {
      const response = await AdminService.singleSubject({ query: { id } });
      form.setFieldValue("subject", response?.message?.subject);
      form.setFieldValue("prompt_category", response?.message?.prompt_category);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    }
  };

  useEffect(() => {
    loadPromptCategories();
    if (update_id) singleSubject(update_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={40}>
      <Col span={10}>
        <Card>
          <Typography.Title level={3}>
            {AppTexts.admin.subjects_page.title}
          </Typography.Title>
          <Space size={20} direction="vertical">
            <Typography.Text>
              {AppTexts.admin.subjects_page.description}
            </Typography.Text>
            <Form onFinish={onSubmitSucceed} form={form}>
              <Form.Item
                name="subject"
                rules={[
                  {
                    required: true,
                    message: AppTexts.global.field_required,
                  },
                ]}
              >
                <Input placeholder="Subject" />
              </Form.Item>

              <Form.Item
                name="prompt_category"
                rules={[
                  { required: true, message: AppTexts.global.field_required },
                ]}
              >
                <Select
                  placeholder={
                    AppTexts.admin.subjects_page.select_prompt_category
                  }
                >
                  {promptCategories?.map(
                    (category: AdminPromptCategoriesI, index: number) => {
                      return (
                        <Select.Option key={`${index}`} value={category._id}>
                          {category.category}
                        </Select.Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="submit-btn"
                >
                  {AppTexts.global.save}
                </Button>
              </Form.Item>
            </Form>
          </Space>
        </Card>
      </Col>
    </Row>
  );
}
