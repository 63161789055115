import { Button, Card, Col, Row, Space, Statistic } from "antd";
import { PlusOutlined, RadarChartOutlined } from "@ant-design/icons";
import { DashboardStatistics } from "../../../utils/types";
import { NavigateFunction } from "react-router-dom";

interface Props {
  statistics: DashboardStatistics;
  navigate: NavigateFunction;
}

export default function StatisticComponent(props: Props) {
  return (
    <Row gutter={16}>
      <Col span={12} className="statistics-col">
        <Card>
          <Space direction="vertical" size={15}>
            <Statistic
              title="Email templates created"
              value={props?.statistics.emailTemplates}
            />
            <Button
              type="primary"
              onClick={() => props?.navigate("/organization/templates")}
              icon={<PlusOutlined />}
            >
              Add new
            </Button>
          </Space>
        </Card>
      </Col>
      <Col span={12} className="statistics-col">
        <Card>
          <Space direction="vertical" size={15}>
            <Statistic
              title="Google accounts"
              value={props?.statistics.googleAccounts}
            />
            <Button
              type="primary"
              onClick={() => props?.navigate("/gmail/accounts")}
              icon={<RadarChartOutlined />}
            >
              Add new
            </Button>
          </Space>
        </Card>
      </Col>
      <Col span={12} className="statistics-col">
        <Card>
          <Space direction="vertical" size={15}>
            <Statistic
              title="Hubspot account"
              value={props?.statistics?.hasHubspotAccount ? "Yes" : "No"}
            />
            <Button
              type="primary"
              onClick={() => props?.navigate("/organization/contacts")}
              icon={<PlusOutlined />}
            >
              Add new
            </Button>
          </Space>
        </Card>
      </Col>
      <Col span={12} className="statistics-col">
        <Card>
          <Space direction="vertical" size={15}>
            <Statistic title="Organization members" value={0} />
            <Button type="primary" icon={<PlusOutlined />}>
              Add new
            </Button>
          </Space>
        </Card>
      </Col>
    </Row>
  );
}
