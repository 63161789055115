import { Button, Space, Typography } from "antd";
import Content from "../../Content";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../store/store";
import { GlobalSliceReducers } from "../../../store/slices/GlobalSlice";
import SwitchToLiveModeContent from "./SwitchToLiveModeContent";
import AppTexts from "../../../utils/texts/AppTexts.json";
import constants from "../../../utils/constants";

export default function LiveMode() {
  const auth = useSelector((state: RootState) => state?.auth.value);
  const organization = useSelector(
    (state: RootState) => state?.organization.value
  );

  const switchToLiveMode = () => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: <SwitchToLiveModeContent />,
      })
    );
  };

  if (
    auth?.session?.access_token &&
    !organization?.live_mode &&
    auth?.user?.role !== constants.user.roles.ADMIN
  ) {
    return (
      <div className="liveModeContainer">
        <Content center>
          <Space size={20}>
            <Typography.Text>{AppTexts.live_mode.header.title}</Typography.Text>
            <Button type="primary" onClick={switchToLiveMode}>
              {AppTexts.live_mode.header.btn_title}
            </Button>
          </Space>
        </Content>
      </div>
    );
  }
  return null;
}
