import ReactQuill from "react-quill"

interface Props {
  content: string,
  onChange: React.Dispatch<React.SetStateAction<string>>
}

export default function ReactQuillComponent(props: Props) {
  const { content, onChange } = props
  return (
    <ReactQuill
      theme="snow"
      preserveWhitespace={true}
      modules={{
        clipboard: {
          matchVisual: false
        }
      }}
      value={content}
      onChange={onChange}
    />
  )
}