import { Popconfirm, Row } from "antd";
import { EmailTemplate } from "../../utils/types";
import ListCard from "../ListCard";
import Loading from "../Loading";

interface Props {
  loading: boolean;
  templates: EmailTemplate[];
  onEdit: Function;
  onDelete: Function;
}

export default function ListTemplates(props: Props) {
  const { loading, templates, onEdit, onDelete } = props;
  return (
    <div>
      {loading && <Loading />}
      <Row gutter={[16, 16]}>
        {templates?.length > 0 &&
          !loading &&
          templates?.map((template: EmailTemplate) => (
            <ListCard
              key={template?._id}
              title={template?.name}
              description={template?.content?.substring(0, 250) + "..."}
              actions={[
                <span onClick={() => onEdit(template)}>Edit</span>,
                <Popconfirm
                  title="Delete the template?"
                  description="Are you sure to delete this template?"
                  onConfirm={() => onDelete(template)}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <span>Delete</span>
                </Popconfirm>,
              ]}
            />
          ))}
      </Row>
    </div>
  );
}
