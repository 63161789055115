import { Endpoint } from "../utils/api/Endpoints";
import {
  ApiFetchRequestOptions,
  BaseApiResponse,
  HubSpotContact,
  HubSpotContactResponse,
  HubSpotContactResponseType,
  HubSpotOAuthCallbackResponse,
  HubSpotOAuthSetupResponse,
  SearchHubSpotContactResponse,
} from "../utils/types";
import ApiProvider from "./AbstractApiProvider";

class HubSpotService extends ApiProvider {
  public async setupOAuth(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<HubSpotOAuthSetupResponse>> {
    return this.callApi(Endpoint.hubspot.setupOAuth, options);
  }
  public async oAuthCallback(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<HubSpotOAuthCallbackResponse>> {
    return this.callApi(Endpoint.hubspot.oAuthCallback, options);
  }
  public async checkOAuthStatus(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<boolean>> {
    return this.callApi(Endpoint.hubspot.checkOAuthStatus, options);
  }
  public async contacts(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<HubSpotContactResponse>> {
    return this.callApi(Endpoint.hubspot.contacts, options);
  }
  public async searchContact(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<SearchHubSpotContactResponse>> {
    return this.callApi(Endpoint.hubspot.searchContact, options);
  }
  public async contactProfile(
    options: ApiFetchRequestOptions ={}
  ): Promise<BaseApiResponse<HubSpotContactResponseType>>{
    return this.callApi(Endpoint.hubspot.contactProfile, options)
  }
  
}

export default new HubSpotService();
