import { Button, Form, Input, message, Checkbox, Row, Col } from "antd";
import {
  CustomContact,
  GoogleAuthSession,
  HubSpotContact,
} from "../../../utils/types";
import AppTexts from "../../../utils/texts/AppTexts.json";
import ResponseError from "../../../utils/api/ResponseError";
import OrganizationService from "../../../services/OrganizationService";
import { useState } from "react";
import ReactQuill from "react-quill";

interface Props {
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  selectedAccount: GoogleAuthSession | null;
  contact: HubSpotContact | CustomContact;
  onClose: Function;
  includeSignature: boolean;
  setIncludeSignature: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Send(props: Props) {
  const [loading, setLoading] = useState(false);
  const [subjectLoading, setSubjectLoading] = useState(false);
  const { selectedAccount, content, contact } = props;
  const [form] = Form.useForm();

  const combinedContact = {
    name: (contact as HubSpotContact)?.properties?.firstname
      ? (contact as HubSpotContact)?.properties?.firstname
      : (contact as CustomContact)?.name,
    email: (contact as HubSpotContact)?.properties?.email
      ? (contact as HubSpotContact)?.properties?.email
      : (contact as CustomContact)?.email,
  };

  const onSubmitSucceed = async (data: { subject: string }) => {
    setLoading(true);
    try {
      const newData = {
        entityId: selectedAccount?.entityId,
        to: (contact as HubSpotContact)?.properties?.email
          ? (contact as HubSpotContact)?.properties?.email
          : (contact as CustomContact)?.email,
        subject: data?.subject,
        content: content,
        include_signature: props?.includeSignature,
        contactName: combinedContact?.name,
      };
      await OrganizationService.sendGeneratedEmail({
        data: newData,
      });
      message.success("Email send successfully.");
      props.onClose();
    } catch (error: unknown) {
      message.error(ResponseError.showMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const generateSubject = async (data: {content: string} ) => {
  setSubjectLoading(true)
  try {
    const result = await OrganizationService.generateEmailSubject({
      data
    });
    form.setFieldValue('subject', result?.message?.openAIContent)
    message.success(AppTexts.send_email.steps.send.subject_generated)
  } catch (error: unknown) {
    message.error(ResponseError.showMessage(error));
  } finally {
    setSubjectLoading(false)
  }
}

return (
  <Form
    layout="vertical"
    name="send-form"
    onFinish={onSubmitSucceed}
    form={form}
  >
    <Form.Item name="from" label="From">
      <Input
        disabled={true}
        defaultValue={selectedAccount?.email}
        value={selectedAccount?.email}
      />
    </Form.Item>
    <Form.Item name="to" label="To">
      <Input
        disabled={true}
        placeholder="To"
        defaultValue={combinedContact?.email}
        value={combinedContact?.email}
      />
    </Form.Item>
    <Row gutter={32} typeof="flex" align="middle">
      <Col span={18}>
        <Form.Item
          rules={[{ required: true, message: AppTexts.global.field_required }]}
          name="subject"
          label="Subject"
        >
          <Input placeholder="Subject" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Button
          type="primary"
          loading={subjectLoading}
          onClick={() => {
            generateSubject({ content });
          }}
        >
          Generate from AI
        </Button>
      </Col>
    </Row>
    <Form.Item
      initialValue={content}
      rules={[{ required: true, message: AppTexts.global.field_required }]}
      name="content"
      label="Content"
    >
      {/* <Input.TextArea rows={16} defaultValue={content} value={content} /> */}
      <ReactQuill
        theme="snow"
        value={content}
        preserveWhitespace={true}
        modules={{
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
        }}
        onChange={(text: string) => props?.setContent(text)}
      />
    </Form.Item>
    <Form.Item>
      <Checkbox
        onChange={(e) => props?.setIncludeSignature(e?.target?.checked)}
        checked={props?.includeSignature}
      >
        Include signature
      </Checkbox>
    </Form.Item>
    <Button
      loading={loading}
      type="primary"
      htmlType="submit"
      className="full-width"
    >
      Submit
    </Button>
  </Form>
);
}
