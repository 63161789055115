import React from "react";
import { Layout, Menu } from "antd";
import { TabletOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Sider } = Layout;

interface IAdminLayoutProps {
  children: React.ReactElement;
}

const routes = [
  {
    path: "/admin/gpt-prompts",
    icon: <TabletOutlined />,
    title: "GPT Prompts",
  },
  {
    path: "/admin/quick-gpt",
    icon: <TabletOutlined />,
    title: "Quick GPT",
  },
  {
    path: "/admin/templates",
    icon: <TabletOutlined />,
    title: "Templates",
  },
  {
    path: "/admin/suggestions",
    icon: <TabletOutlined />,
    title: "Suggestions",
  },
  {
    path: "/admin/subjects",
    icon: <TabletOutlined />,
    title: "Subjects",
  },
];

export default function AdminLayout(props: IAdminLayoutProps) {
  return (
    <Layout className="user-layout-wrapper">
      <Sider width={200} className="sider">
        <Menu mode="inline" defaultSelectedKeys={["1"]} className="aside-menu">
          {routes?.map((route, index) => (
            <Menu.Item key={route?.path} icon={<TabletOutlined />}>
              <Link to={route?.path}>{route?.title}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Layout.Content className="layout-content">
          {props.children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
