import { useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Input,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import Content from "../Content";
import HubSpotLogo from "../../assets/images/hubspot_icon.svg";
import {
  HubSpotBatchCompaniesResult,
  HubSpotContact,
  HubSpotContactResponse,
  SearchHubSpotContactResponse,
} from "../../utils/types";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import SendEmailForm from "../Forms/SendEmailForm";
import ResponseError from "../../utils/api/ResponseError";
import HubSpotService from "../../services/HubSpotService";
import AppTexts from "../../utils/texts/AppTexts.json";
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";

interface HubSpotContactsI {
  loading: boolean;
  hasHubSpotIntegration: boolean;
  integrateHubSpot: Function;
  contacts: HubSpotContactResponse;
  loadMore: Function;
}

export default function HubSpotContacts(props: HubSpotContactsI) {
  const navigate = useNavigate();
  const {
    loading,
    hasHubSpotIntegration,
    integrateHubSpot,
    contacts,
    loadMore,
  } = props;

  const [form] = Form.useForm();

  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [searchContactLoading, setSearchContactLoading] = useState(false);
  const [searchedContacts, setSearchedContacts] =
    useState<SearchHubSpotContactResponse>({
      total: 1,
      results: [],
    });
    const handleRowClick = (record: HubSpotContact ) => {
   
      navigate(`/organization/contact/profile?id=${record.id}&hubspot=${true}`);

    };
  const [sendEmailModal, setSendEmailEmailModal] = useState<{
    show: boolean;
    contact: HubSpotContact | null;
  }>({
    show: false,
    contact: null,
  });

  const getRowCompany = (row: HubSpotContact) => {
    if (row?.company) {
      return {
        name: row?.company?.name?.value,
        domain: row?.company?.website?.value,
      };
    }
    const current_ids = row?.associations?.companies?.results?.map(
      (elem: { id: string; type: string }) => elem.id
    );
    const property = contacts?.companies?.results?.find(
      (company: HubSpotBatchCompaniesResult) =>
        current_ids?.includes(company.properties?.hs_object_id)
    )?.properties;
    return property;
  };

  const columns: ColumnsType<HubSpotContact > = [
    {
      title: "Name",
      render: (row: HubSpotContact) =>
        `${row?.properties?.firstname} ${row?.properties?.lastname}`,
    },
    {
      title: "Email",
      render: (row: HubSpotContact) => row?.properties?.email,
    },
    {
      title: "Company",
      render: (row: HubSpotContact) => getRowCompany(row)?.name,
    },
    {
      title: "Domain",
      render: (row: HubSpotContact) => getRowCompany(row)?.domain,
    },
    {
      title: "Actions",
      render: (row: HubSpotContact) => (
        <Dropdown
          menu={{
            items: [
              {
                label: (
                  <span
                    onClick={() =>
                      setSendEmailEmailModal({ show: true, contact: row })
                    }
                  >
                    Send email
                  </span>
                ),
                key: "1",
              },
            ],
          }}
          trigger={["click"]}
        >
          <MoreOutlined onClick={(e) => e.preventDefault()} />
        </Dropdown>
      ),
    },
  ];

  const onSubmitSearch = async (data: { term: string; after?: string }) => {
    if (data?.term?.trim()?.length === 0) {
      setSearchedContacts({});
      setSearchContactLoading(false);
      return;
    }
    setSearchContactLoading(true);
    try {
      const query = { term: data.term };
      const response = await HubSpotService.searchContact({
        query: data?.after ? { ...query, after: data.after } : query,
      });
      if (response?.message?.results?.length === 0) {
        message.error(
          AppTexts.organization_templates.contacts.search_contacts_not_found
        );
      } else {
        message.success(
          `${response?.message?.total} ${AppTexts.organization_templates.contacts.results_were_found}`
        );
      }

      setSearchedContacts(
        data?.after
          ? {
              results: [
                ...(searchedContacts?.results as HubSpotContact[]),
                ...(response?.message?.results as HubSpotContact[]),
              ],
              paging: response?.message?.paging,
            }
          : response.message
      );
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
      setSearchedContacts({});
    } finally {
      setSearchContactLoading(false);
    }
  };

  const handleLoadMore = async () => {
    setLoadMoreLoading(true);
    if (searchedContacts?.results && searchedContacts?.results?.length > 0) {
      await onSubmitSearch({
        term: form.getFieldValue("term"),
        after: searchedContacts?.paging?.next?.after,
      });
    } else {
      await loadMore();
    }
    setLoadMoreLoading(false);
  };

  return (
    <div>
      <Content center>
        {loading ? (
          <Loading />
        ) : !hasHubSpotIntegration ? (
          <Card className="card-wrapper">
            <Content center>
              <div>
                <Typography.Title level={3}>
                  HubSpot Integration
                </Typography.Title>
                <Typography.Paragraph>
                  Our platform offers a convenient and secure way to import your
                  contacts directly from HubSpot. By using our service, you can
                  easily transfer your contact list without worrying about any
                  security concerns.
                </Typography.Paragraph>
                <div className="integrate-hubspot-wrapper">
                  <img
                    src={HubSpotLogo}
                    width="100"
                    height="30"
                    alt="HubSpot Logo"
                  />
                  <Button onClick={() => integrateHubSpot()}>
                    Integrate HubSpot
                  </Button>
                </div>
              </div>
            </Content>
          </Card>
        ) : (
          <></>
        )}
      </Content>

      {!loading && hasHubSpotIntegration && (
        <div>
          <Form onFinish={onSubmitSearch} form={form}>
            <Space>
              <Form.Item name="term">
                <Input
                  addonBefore={<SearchOutlined />}
                  placeholder="Search for contacts..."
                />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" loading={searchContactLoading}>
                  Search
                </Button>
              </Form.Item>
            </Space>
          </Form>
          <Table
            dataSource={
              searchedContacts?.results && searchedContacts?.results?.length > 0
                ? searchedContacts?.results
                : contacts.contacts?.results
            }
            columns={columns}
            pagination={false}
          />

          <Content center>
            <Space size={10}>
              <Button
                loading={loadMoreLoading}
                onClick={() => handleLoadMore()}
                disabled={
                  searchedContacts?.results &&
                  searchedContacts?.results?.length > 0
                    ? !searchedContacts?.paging?.next?.after
                    : !contacts?.contacts?.paging?.next?.after
                }
              >
                Load more
              </Button>
            </Space>
          </Content>
        </div>
      )}
      {sendEmailModal.show && sendEmailModal.contact && (
        <SendEmailForm
          contact={sendEmailModal.contact}
          onClose={() => {
            setSendEmailEmailModal({
              show: false,
              contact: null,
            });
          }}
        />
      )}
    </div>
  );
}
