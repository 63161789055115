import React, { useEffect } from "react";
import { WithGlobalDataProps } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import UserService from "../../services/UserService";
import { GlobalSliceReducers } from "../../store/slices/GlobalSlice";
import { useDispatch } from "react-redux";
import ResponseError from "../api/ResponseError";
import OrganizationService from "../../services/OrganizationService";
import { message } from "antd";

export function WithGlobalData<P>(
  WrappedComponent: React.ComponentType<P & WithGlobalDataProps>
) {
  const Component = (props: P) => {
    const dispatch = useDispatch();
    const global = useSelector((state: RootState) => state.global.value);

    const loadTemplateSuggestions = async () => {
      try {
        const response = await OrganizationService.loadTemplateSuggestions();
        dispatch(GlobalSliceReducers.setTemplateSuggestions(response?.message));
      } catch (err) {
        message.error(ResponseError.showMessage(err));
      }
    };

    const loadOrganizations = async () => {
      try {
        const response = await UserService.listOrganizations();
        dispatch(GlobalSliceReducers.setOrganizations(response?.message));
      } catch (err) {
        message.error(ResponseError.showMessage(err));
      }
    };

    useEffect(() => {
      loadOrganizations();
      loadTemplateSuggestions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <WrappedComponent
        {...props}
        organizations={global.organizations}
        template_suggestions={global?.template_suggestions}
      />
    );
  };

  return Component;
}
