import { Endpoint } from "../utils/api/Endpoints";
import {
  ApiFetchRequestOptions,
  BaseApiResponse,
  ErrorResponse,
  Organization,
  TwoFactorAuthResponse,
  User,
  UserLoginSessionResponse,
  UserLoginTFAResponse,
  UserSession,
  UserSettings,
} from "../utils/types";
import ApiProvider from "./AbstractApiProvider";

export class UserService extends ApiProvider {
  public async login(
    options: ApiFetchRequestOptions = {}
  ): Promise<UserLoginTFAResponse | UserLoginSessionResponse> {
    return this.callApi(Endpoint.user.auth.login, options);
  }

  public async logout(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<boolean>> {
    return this.callApi(Endpoint.user.auth.logout, options);
  }

  public async register(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<string>> {
    return this.callApi(Endpoint.user.auth.register, options);
  }

  public async verifyAccount<T>(
    options: ApiFetchRequestOptions = {}
  ): Promise<T> {
    return this.callApi<T>(Endpoint.user.auth.verify_account, options);
  }

  public async forgotPassword(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<string>> {
    return this.callApi(Endpoint.user.auth.forgot_password, options);
  }

  public async resetPassword(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<boolean>> {
    return this.callApi(Endpoint.user.auth.reset_password, options);
  }

  public async updateProfile(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<boolean>> {
    return this.callApi(Endpoint.user.auth.update_profile, options);
  }

  public async updatePassword(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<string>> {
    return this.callApi(Endpoint.user.auth.update_password, options);
  }

  public async getProfile(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<User>> {
    return this.callApi(Endpoint.user.auth.get_profile, options);
  }

  public async twoFactorAuthSubmit(
    options: ApiFetchRequestOptions = {}
  ): Promise<TwoFactorAuthResponse> {
    return this.callApi(Endpoint.user.auth.two_factor_auth_submit, options);
  }

  public async createOrganization(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<Organization>> {
    return this.callApi<BaseApiResponse<Organization>>(
      Endpoint.user.organizations.create,
      options
    );
  }

  public async listOrganizations(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<Organization[]>> {
    return this.callApi<BaseApiResponse<Organization[]>>(
      Endpoint.user.organizations.list,
      options
    );
  }

  public async reGenerateAccessToken(options: ApiFetchRequestOptions = {}) {
    try {
      return await this.callApi<BaseApiResponse<UserSession>>(
        Endpoint.user.auth.reGenerateAccessToken,
        options
      );
    } catch (err: unknown) {
      console.log("err while generating new token:", err);
      return err;
    }
  }

  public static accessTokenExpired(response: unknown) {
    //TODO: Move the access token expired string into constants
    return (
      (response as ErrorResponse)?.name === "auth-access-token-expired-error"
    );
  }
  public static accessTokenHasBeenRefreshed(response: unknown) {
    return (response as BaseApiResponse<UserSession>)?.message?.access_token;
  }

  public async getUserSettings(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<UserSettings>> {
    return await this.callApi<BaseApiResponse<UserSettings>>(
      Endpoint.user.auth.get_user_settings,
      options
    );
  }

  public async UpdateUserSettings(
    options: ApiFetchRequestOptions = {}
  ): Promise<BaseApiResponse<boolean>> {
    return await this.callApi<BaseApiResponse<boolean>>(
      Endpoint.user.auth.update_user_settings,
      options
    );
  }
}

export default new UserService();
