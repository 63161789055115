import Header from "../../components/Header";
import UserLayout from "../../components/Layouts/UserLayout";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useDispatch } from "react-redux";
import Statistics from "../../components/Dashboard/Statistics";
import { useNavigate } from "react-router-dom";
import { WithGlobalData } from "../../utils/hooks/withGlobalData";
import { DashboardStatistics, WithGlobalDataProps } from "../../utils/types";
import { useEffect, useState } from "react";
import ResponseError from "../../utils/api/ResponseError";
import { message } from "antd";
import OrganizationService from "../../services/OrganizationService";

interface IHomePage extends WithGlobalDataProps {}

const HomePage = (props: IHomePage) => {
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth.value);
  const organization = useSelector(
    (state: RootState) => state.organization.value
  );
  const dispatch = useDispatch();

  const [statistics, setStatistics] = useState<DashboardStatistics>({
    emailTemplates: 0,
    googleAccounts: 0,
    hasHubspotAccount: false,
  });

  const loadStatistics = async () => {
    try {
      const response = await OrganizationService.dashboardStatistics({});
      setStatistics(response?.message);
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  useEffect(() => {
    loadStatistics();
  }, []);

  return (
    <div className="layout">
      <Header
        dispatch={dispatch}
        organization={organization}
        organizations={props.organizations}
        auth={auth}
      />
      <UserLayout>
        <Statistics statistics={statistics} navigate={navigate} />
      </UserLayout>
    </div>
  );
};

export default WithGlobalData(HomePage);
