import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import HubSpotService from "../../services/HubSpotService";
import ResponseError from "../../utils/api/ResponseError";
import { message } from "antd";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { WithGlobalData } from "../../utils/hooks/withGlobalData";
import Header from "../../components/Header";
import { WithGlobalDataProps } from "../../utils/types";
import UserLayout from "../../components/Layouts/UserLayout";
import AppTexts from "../../utils/texts/AppTexts.json";

interface IHubspotOAuthCallbackPage extends WithGlobalDataProps {}

function HubspotOAuthCallbackPage(props: IHubspotOAuthCallbackPage) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const organization = useSelector(
    (state: RootState) => state.organization.value
  );
  const auth = useSelector((state: RootState) => state.auth.value);

  const hubSpotOAuthCode = async () => {
    try {
      const query = { code: searchParams.get("code") };
      await HubSpotService.oAuthCallback({ query });
      message.success(AppTexts.global.hubspot_connected_successfully);
      navigate("/organization/contacts");
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  useEffect(() => {
    hubSpotOAuthCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="layout">
      <Header
        dispatch={dispatch}
        organization={organization}
        organizations={props.organizations}
        auth={auth}
      />

      <UserLayout>
        <h1>Connecting hubspot integration</h1>
      </UserLayout>
    </div>
  );
}

export default WithGlobalData(HubspotOAuthCallbackPage);
