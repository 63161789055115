import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../store/store";

interface Props {
  children: React.ReactElement;
}

const PrivateRoute: React.FC<Props> = ({ children }) => {
  const auth = useSelector((state: RootState) => state?.auth);

  if (!auth.value.session.access_token) {
    return <Navigate to="/auth/login" replace />;
  }

  return children;
};

export default PrivateRoute;
