import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import constants from "../constants";
import Content from "../../components/Content";
import AppTexts from "../texts/AppTexts.json";
import { Button, Card, Layout, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";

interface Props {
  children: React.ReactElement;
}

export default function AdminRole(props: Props) {
  const auth = useSelector((state: RootState) => state?.auth?.value);
  const navigate = useNavigate();
  return (
    <div>
      {auth?.user?.role === constants.user.roles.ADMIN ? (
        props?.children
      ) : (
        <Layout className="layout admin-role">
          <Content center>
            <Card className="card-wrapper">
              <Typography.Title level={3}>
                {AppTexts.admin.layout.permission_error.title}
              </Typography.Title>
              <Space direction="vertical">
                <Typography.Paragraph>
                  {AppTexts.admin.layout.permission_error.description}
                </Typography.Paragraph>
                <Button type="primary" onClick={() => navigate("/")}>
                  {AppTexts.admin.layout.permission_error.button_title}
                </Button>
              </Space>
            </Card>
          </Content>
        </Layout>
      )}
    </div>
  );
}
