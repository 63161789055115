import { HubSpotContactResponseType } from "../../utils/types"

interface Props {
    profile:  HubSpotContactResponseType 
    hubspotProfileNotes?: String[]
  }
const CostumeContactProfile = (props : Props) =>{
    return (
        <div>
            <pre>{JSON.stringify(props, null, 3)}</pre>
        </div>
    )
}

export default CostumeContactProfile