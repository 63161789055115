import { Button, Card, Layout, Result } from "antd";
import { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { useNavigate, useSearchParams } from "react-router-dom";
import Content from "../../../components/Content";
import AppTexts from "../../../utils/texts/AppTexts.json";
import Loading from "../../../components/Loading";

export default function VerifyAccountPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const verifyAccount = async () => {
    try {
      await UserService.verifyAccount({
        data: { token: searchParams.get("token") },
      });
      setSuccess(true);
    } catch (err: unknown) {
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="layout verify-account-page">
      <Content center>
        <Card className="card-wrapper">
          {!loading && !success && (
            <Result
              status="error"
              title={AppTexts.auth.verify_account.title_failed}
              subTitle={AppTexts.auth.verify_account.description_failed}
              extra={[
                <Button
                  onClick={() => navigate("/auth/login")}
                  type="primary"
                  key="console"
                >
                  {AppTexts.auth.verify_account.login}
                </Button>,
              ]}
            ></Result>
          )}
          {!loading && success && (
            <Result
              status="success"
              title={AppTexts.auth.verify_account.title}
              subTitle={AppTexts.auth.verify_account.description}
              extra={[
                <Button
                  onClick={() => navigate("/auth/login")}
                  type="primary"
                  key="console"
                >
                  {AppTexts.auth.verify_account.login}
                </Button>,
              ]}
            />
          )}
          {loading && <Loading />}
        </Card>
      </Content>
    </Layout>
  );
}
