import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ResponseError from "../../utils/api/ResponseError";
import { message } from "antd";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { WithGlobalData } from "../../utils/hooks/withGlobalData";
import Header from "../../components/Header";
import { WithGlobalDataProps } from "../../utils/types";
import UserLayout from "../../components/Layouts/UserLayout";
import AppTexts from "../../utils/texts/AppTexts.json";
import GoogleService from "../../services/GoogleService";

interface IGmailOAuthCallbackPage extends WithGlobalDataProps {}

function GmailOAuthCallbackPage(props: IGmailOAuthCallbackPage) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const organization = useSelector(
    (state: RootState) => state.organization.value
  );
  const auth = useSelector((state: RootState) => state.auth.value);

  const googleOAuthCode = async () => {
    try {
      const query = { code: searchParams.get("code") };
      await GoogleService.oAuthCallback({ query });
      message.success(AppTexts.global.google_connected_successfully);
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    } finally {
      navigate("/organization/email");
    }
  };

  useEffect(() => {
    googleOAuthCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="layout">
      <Header
        dispatch={dispatch}
        organization={organization}
        organizations={props.organizations}
        auth={auth}
      />

      <UserLayout>
        <p>Please wait...</p>
      </UserLayout>
    </div>
  );
}

export default WithGlobalData(GmailOAuthCallbackPage);
