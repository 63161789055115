import UserLayout from "../../../components/Layouts/UserLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { GoogleAuthSession, UserSettings, WithGlobalDataProps } from "../../../utils/types";
import { WithGlobalData } from "../../../utils/hooks/withGlobalData";
import Header from "../../../components/Header";
import ProfileLayout from "../../../components/Layouts/ProfileLayout";
import { useEffect, useState } from "react";
import GoogleService from "../../../services/GoogleService";
import UserService from "../../../services/UserService";
import { message } from "antd";
import ResponseError from "../../../utils/api/ResponseError";

interface IProfilePageProps extends WithGlobalDataProps { }

function ProfilePage(props: IProfilePageProps) {
  const [accounts, setAccounts] = useState<GoogleAuthSession[]>([])
  const [settings, setSettings] = useState<UserSettings>()
  const auth = useSelector((state: RootState) => state.auth.value);
  const organization = useSelector(
    (state: RootState) => state.organization.value
  );
  const dispatch = useDispatch();

  const loadEmails = async () => {
    try {
      const result = await GoogleService.loadAccounts();
      setAccounts(result.message)
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err))
    }
  }

  const loadSettings = async () => {
    try {
      const result = await UserService.getUserSettings();
      setSettings(result.message)
    } catch (err: unknown) {
    }
  }

  useEffect(() => {
    loadEmails()
    loadSettings()
  }, [])

  return (
    <div className="layout">
      <Header
        dispatch={dispatch}
        organization={organization}
        organizations={props.organizations}
        auth={auth}
      />
      <UserLayout>
        <ProfileLayout dispatch={dispatch} accounts={accounts} settings={settings} organization={organization} />
      </UserLayout>
    </div>
  );
}

export default WithGlobalData(ProfilePage);
