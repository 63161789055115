import { Button, Form, Input, Space, Typography } from "antd";
import AppTexts from "../../../utils/texts/AppTexts.json";

interface Props {
  onSubmitCallback: Function;
}

export default function EnterEmail(props: Props) {
  const onSubmitSucceed = (data: object) => {
    props?.onSubmitCallback(data);
  };
  return (
    <div>
      <Space direction="vertical">
        <Typography.Title level={4}>
          Simply provide the recipient's basic information to initiate the
          sending process
        </Typography.Title>

        <Form layout="vertical" onFinish={onSubmitSucceed}>
          <Form.Item name="name" label="Recipient's name">
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Recipient's email address"
            name="email"
            rules={[
              { required: true, message: AppTexts.global.field_required },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button block htmlType="submit">
              {AppTexts.global.next}
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
}
