import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { AuthUserSlice, User } from "../../utils/types";
import { UserSession } from "../../utils/types";

const persistConfig = {
  key: "fluxigniter-auth",
  storage,
};

const initialState: { value: AuthUserSlice } = {
  value: {
    user: {
      _id: "",
      email: "",
      first_name: "",
      last_name: "",
      role: "",
      verified: false,
      two_factor_auth_enabled: false,
      createdAt: "",
      updatedAt: "",
    },
    session: {
      user_id: "",
      access_token: "",
      refresh_token: "",
    },
    tfaAuthToken: "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<AuthUserSlice>) => {
      state.value = { ...action.payload };
    },
    logout: (state) => {
      const temp = { ...state.value };
      temp.session = { ...initialState.value.session };
      temp.user = { ...initialState.value.user };
      state.value = temp;
    },
    reGenerateAccessToken: (state, action: PayloadAction<UserSession>) => {
      const temp = { ...state.value };
      temp.session.access_token = action.payload.access_token;
      temp.session.refresh_token = action.payload.refresh_token;
      state.value = temp;
    },
    updateUser: (state, action: PayloadAction<User> ) => {
      state.value.user = action.payload
    },
  },
});

export const AuthSliceReducers = authSlice.actions;

export default persistReducer(persistConfig, authSlice.reducer);
