import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import { RootState, store } from "../../store/store";
import { WithGlobalData } from "../../utils/hooks/withGlobalData";
import {
  CustomContact,
  HubSpotBatchCompaniesResult,
  HubSpotContact,
  HubSpotContactResponse,
  WithGlobalDataProps,
} from "../../utils/types";
import UserLayout from "../../components/Layouts/UserLayout";
import { Button, Dropdown, message, Tabs } from "antd";
import HubSpotService from "../../services/HubSpotService";
import ResponseError from "../../utils/api/ResponseError";
import { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { MoreOutlined } from "@ant-design/icons";
import CustomContacts from "../../components/Contacts/CustomContacts";
import OrganizationService from "../../services/OrganizationService";
import { GlobalSliceReducers } from "../../store/slices/GlobalSlice";
import AddCustomContact from "../../components/Contacts/AddCustomContact";
import HubSpotContacts from "../../components/Contacts/HubspotContacts";
import SendEmailForm from "../../components/Forms/SendEmailForm";

interface IOrganizationContactsPage extends WithGlobalDataProps {}

function OrganizationContactsPage(props: IOrganizationContactsPage) {
  const [customContacts, setCustomContacts] = useState<CustomContact[]>([]);
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState<HubSpotContactResponse>({
    contacts: {
      results: [],
      paging: {
        next: {
          after: "",
          link: "",
        },
      },
    },
    companies: {
      status: "",
      results: [],
      startedAt: "",
      completedAt: "",
    },
  });
  const [hasHubSpotIntegration, setHasHubSpotIntegration] = useState(false);
  const [sendEmailModal, setSendEmailEmailModal] = useState<{
    show: boolean;
    contact: CustomContact | null;
  }>({
    show: false,
    contact: null,
  });

  const auth = useSelector((state: RootState) => state.auth.value);
  const organization = useSelector(
    (state: RootState) => state.organization.value
  );
  const dispatch = useDispatch();
  const organization_headers = {
    organization_id: organization._id,
  };

  const integrateHubSpot = async () => {
    try {
      const response = await HubSpotService.setupOAuth({
        headers: organization_headers,
      });
      window.location.replace(response.message.oauth_url);
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    }
  };

  const loadHubSpotContacts = async (after?: string) => {
    const response = await HubSpotService.contacts({
      headers: organization_headers,
      query: after ? { after } : {},
    });

    const temp = {
      contacts: {
        ...response.message.contacts,
        results: [
          ...contacts.contacts.results,
          ...response.message.contacts.results,
        ],
      },
      companies: {
        ...response.message.companies,
        results: [
          ...contacts.companies.results,
          ...response.message.companies.results,
        ],
      },
    };
    setContacts(temp);
  };

  const checkHubSpotOAuth = async () => {
    try {
      const response = await HubSpotService.checkOAuthStatus({
        headers: organization_headers,
      });
      await loadHubSpotContacts();
      setHasHubSpotIntegration(response.message);
    } catch (err: unknown) {
      message.error(ResponseError.showMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const loadCustomContacts = async () => {
    try {
      const response = await OrganizationService.loadCustomContacts();
      setCustomContacts(response.message);
    } catch (error) {
      message.error(ResponseError.showMessage(error));
    }
  };

  useEffect(() => {
    checkHubSpotOAuth();
    loadCustomContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRowCompany = (row: HubSpotContact) => {
    const current_ids = row?.associations?.companies?.results?.map(
      (elem: { id: string; type: string }) => elem.id
    );
    const property = contacts?.companies?.results?.find(
      (company: HubSpotBatchCompaniesResult) =>
        current_ids?.includes(company.properties?.hs_object_id)
    )?.properties;
    return property;
  };

  const columns: ColumnsType<HubSpotContact | CustomContact> = [
    {
      title: "Name",
      render: (row: HubSpotContact | CustomContact) =>
        `${
          (row as HubSpotContact)?.properties?.firstname ||
          (row as CustomContact).name
        } ${(row as HubSpotContact)?.properties?.lastname || ""}`,
    },
    {
      title: "Email",
      render: (row: HubSpotContact | CustomContact) =>
        (row as HubSpotContact)?.properties
          ? (row as HubSpotContact)?.properties?.email
          : (row as CustomContact)?.email,
    },
    {
      title: "Company",
      render: (row: HubSpotContact | CustomContact) =>
        (row as HubSpotContact)?.properties
          ? getRowCompany(row as HubSpotContact)?.name
          : (row as CustomContact)?.name,
    },
    {
      title: "Domain",
      render: (row: HubSpotContact | CustomContact) =>
        (row as HubSpotContact)?.properties
          ? getRowCompany(row as HubSpotContact)?.domain
          : (row as CustomContact)?.domain,
    },
    {
      title: "Actions",
      render: (row: CustomContact) => (
        <Dropdown
          menu={{
            items: [
              {
                label: (
                  <span
                    onClick={() => {
                      setSendEmailEmailModal({ show: true, contact: row });
                    }}
                  >
                    Send email
                  </span>
                ),
                key: "1",
              },
            ],
          }}
          trigger={["click"]}
        >
          <MoreOutlined onClick={(e) => e.preventDefault()} />
        </Dropdown>
      ),
    },
  ];

  const loadMore = async () => {
    await loadHubSpotContacts(contacts.contacts.paging.next.after);
  };

  const addNewCustomContact = async () => {
    store.dispatch(
      GlobalSliceReducers.showModal({
        component: (
          <AddCustomContact
            onSuccessCallback={() => {
              store.dispatch(GlobalSliceReducers.closeModal());
              loadCustomContacts();
            }}
          />
        ),
      })
    );
  };

  return (
    <div className="layout hubspot-contacts-page">
      <Header
        dispatch={dispatch}
        organization={organization}
        organizations={props.organizations}
        auth={auth}
      />
      <UserLayout>
        <div>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: `Hubspot contacts`,
                children: (
                  <HubSpotContacts
                    loading={loading}
                    hasHubSpotIntegration={hasHubSpotIntegration}
                    integrateHubSpot={integrateHubSpot}
                    contacts={contacts}
                    loadMore={loadMore}
                  />
                ),
              },
              {
                key: "2",
                label: `Custom contacts`,
                children: (
                  <div>
                    {customContacts?.length > 0 && (
                      <div className="add-new-contact-btn">
                        <Button onClick={addNewCustomContact}>Add new</Button>
                      </div>
                    )}
                    <CustomContacts
                      columns={columns}
                      customContacts={customContacts}
                      loadCustomContacts={loadCustomContacts}
                    />
                  </div>
                ),
              },
            ]}
            onChange={() => {}}
          />
        </div>
      </UserLayout>
      {sendEmailModal.show && sendEmailModal.contact && (
        <SendEmailForm
          contact={sendEmailModal.contact}
          onClose={() => {
            setSendEmailEmailModal({
              show: false,
              contact: null,
            });
          }}
        />
      )}
    </div>
  );
}

export default WithGlobalData(OrganizationContactsPage);
